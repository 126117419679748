import React, { useState, useEffect } from 'react';
import generalApi from './../services/generalApi';
import Loader from './../component/shared/loader';
import { useTranslation } from 'react-multi-lang'
function Notification() {
    const t = useTranslation()
    const [loading, setLoading] = useState(true);
    const [statment, setStatement] = useState([]);
    useEffect(() => {
        getreffral()
    }, []);
    const getreffral = () => {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem('user_data'));
        const json = {
            customer: userData['customerid'],
            certificate: userData['certificate'],
        };
        generalApi.getData('SSMReferalDashboard', json).then((response) => {
            console.log('dhdhdh', response['data']);
            setStatement(response['data']);
            setLoading(false);
        });
    }
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div className='main-container'>
            <div className='container'>
                <div className='row mx-0'>
                    <div className='col-md-4 mx-auto content-box'>
                        <div className='card'>
                            <div className='card-body text-center'>
                                <i className='iconsminds-clock'></i>
                                <p className='font-weight-bold f-17 mb-0'>{t('order.refferal')}</p>
                                <p className='lead text-center'>{statment["referal"]} </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 mx-auto content-box'>
                        <div className='card'>
                            <div className='card-body text-center'>
                                <i className='iconsminds-clock'></i>
                                <p className='font-weight-bold f-17 mb-0'>{t('order.tran_month')}</p>
                                <p className='lead text-center'>{statment["txn"]} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5 mt-5">
                    <div className='col-md-4 mx-auto '>
                        <button className="btn btn-outline-primary btn-block" onClick={getreffral}>{t('order.refresh')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Notification;
