import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import generalApi from '../../services/generalApi'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang'
class ChabgePassword extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        const { t } = this.props
        this.validator = new SimpleReactValidator({
            messages: {
                required: t("validation.required"),
                phone: t("validation.phone"),
                min: t("validation.pinmin"),
                max: t("validation.pinmax"),
                email: t("validation.email"),
            },
        });
        this.state = {
            isactive: false,
            pin: '',
            oldpin: '',
            newpin: '',
        }
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
        console.log("Checked", e.target.checked);

    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            let userdata = JSON.parse(localStorage.getItem("user_data"))
            const json = {
                pin: this.state.oldpin,
                certificate: userdata["certificate"],
                customer: userdata["customerid"],
            }
            // Valaidate PAssword
            generalApi.getData('SSMvalidatePassword', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    // Save new password
                    const json = {
                        idnumber: this.state.newpin,
                        certificate: userdata["certificate"],
                        customer: "+" + userdata["mobilenumber"],
                        fbtoken: localStorage.getItem('fcm-token')
                    }
                    generalApi.getData('SSMsavePIN', json).then(response => {
                        if (response["data"]["statuscode"] == "000") {
                            const { t } = this.props
                            let message = { heading: t('message.success'), des: t('message.changepassmessage'), link: "/", linkname: t('message.home') }
                            localStorage.setItem('message', JSON.stringify(message))
                            this.props.history.push('/message')
                        } else {
                            this.setState({ isactive: false });
                            toast.error(response["data"]["statusdesc"]);
                        }
                    });
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const { t } = this.props
        return (
            <div className="main-container" >
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-2"></div>
                        <div className="col-8 ">
                            <div className="card card-default signup-box" >
                                <h3 className="text-center mt-4">{t('changepin.update_pin')}</h3>
                                <div className="card-body">
                                    <form onSubmit={this.submitForm}>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="control-label">{t('changepin.old_pin')}</label>
                                                <div className="input-icon">
                                                    <i className='icon-lock fa'></i>
                                                    <input placeholder={t('changepin.old_pin')} value={this.state.oldpin} name="oldpin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('oldpin')} value={this.state.oldpin} className="form-control input-md englisg-text" type="password" />
                                                </div>
                                                <span className="text-danger">{this.validator.message('oldpin', this.state.oldpin, 'required|min:4|max:4')}</span>
                                            </div>
                                            <div className="col-12">
                                                <label className="control-label">{t('changepin.new_pin')}</label>
                                                <div className="input-icon">
                                                    <i className='icon-lock fa'></i>
                                                    <input placeholder={t('changepin.new_pin')} value={this.state.newpin} name="newpin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('newpin')} value={this.state.newpin} className="form-control input-md englisg-text" type="password" />
                                                </div>
                                                <span className="text-danger">{this.validator.message('newpin', this.state.newpin, 'required|min:4|max:4')}</span>
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <button type="submit" disabled={this.state.isactive ? true : false} className="btn btn-primary">
                                                {this.state.isactive ? t('changepin.processing') : t('changepin.update')}
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation(ChabgePassword));