import React, { Component } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import generalApi from '../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang'
class ResetPin extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        const { t } = this.props
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                phone: t('validation.phone'),
                min: t('validation.pinmin'),
                max: t('validation.pinmax'),
                email: t('validation.email')
            }
        });
        this.state = { isactive: false, idnumber: '', resetData: JSON.parse(localStorage.getItem('reset_pin')) }
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            const s = { ...this.state["resetData"] }
            const json = {
                ...s,
                idnumber: this.state.idnumber,
                fbtoken: localStorage.getItem('fcm-token')
            }
            // console.log(json);

            generalApi.getData('SSMsavePIN', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    // toast.success(response["data"]["statusdesc"]);
                    localStorage.removeItem('reset_pin');
                    if (!this.props.isModel) {
                        this.props.history.push('/login')
                    } else {
                        this.props.loginOpen();
                    }
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }

            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const { t } = this.props
        if (!this.state.resetData) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <div className="main-container" >
                <div className="container">
                    <div className="row">
                        <div className={`
									${this.props.isModel ? 'col-12 login-box' : 'col-sm-5 login-box signup-box'}
								`}>
                            <div className="card card-default">
                                <div className="panel-intro text-center">
                                    <img src="/images/logo.png" className="page-logo-width" alt="" />
                                </div>
                                <div className="card-body">
                                    <form role="form" onSubmit={this.submitForm}>
                                        <div className="form-group">
                                            <label className="control-label">{t('reset_pin.new_pin')}</label>

                                            <div className="input-icon"><i className="icon-lock fa"></i>
                                                <input id="sender-email" type="password" name="idnumber" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('idnumber')} value={this.state.idnumber} placeholder={t('reset_pin.enter_pin')} className="form-control email englisg-text" />
                                                <span className="text-danger">{this.validator.message('idnumber', this.state.idnumber, 'required|min:4|max:4')}</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" disabled={this.state.isactive ? true : false} className="btn btn-primary btn-lg btn-block">
                                                {this.state.isactive ? t('other.processing') : t('reset_pin.reset')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="card-footer">
                                    <p className="text-center "><Link to="/login"> Back to Login </Link></p>
                                </div> */}
                            </div>
                            {/* <div className="login-box-btm text-center">
                                <p> Don't have an account?
                                    <Link to="signup"><strong>Sign Up !</strong> </Link></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default withRouter(withTranslation(ResetPin));