import { createStore, combineReducers, applyMiddleware } from "redux";
// import logger from "redux-logger";
import generalData from "./reducers/generalReducers";
import signUpData from "./reducers/signUpReducer";
// import thunk from "redux-thunk";
export default createStore(
  combineReducers({
    generalData,
    signUpData
  })
  //   {},
  //   applyMiddleware(logger, thunk)
);
