import React, { useState } from 'react';
import generalApi from '../../services/generalApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-multi-lang';
const AppBanner = (props) => {
    const t = useTranslation();
    const [isactive, setActive] = useState(false);
    const [phone, setPhone] = useState('');
    let mobileprefix = props.mobileprefix;
    const changeHandler = (e) => {
        let mobile = e.target.value.slice(mobileprefix.length);
        if (mobile.length > 9) {
            mobile = mobile.substring(0, 9);
        }
        setPhone(mobile);
    };
    const sendLink = (e) => {
        e.preventDefault();
        if (phone.length < 9) {
            toast.error(t('header.valid_mobile'));
            return;
        }
        setActive(true);
        const json = { mobile: mobileprefix + phone };
        generalApi.getData('SSMSendSMS', json).then((response) => {
            if (response['data']['statuscode'] == '000') {
                setActive(false);
                setPhone('');
                toast.success(response['data']['statusdesc']);
            } else {
                setActive(false);
                toast.error(response['data']['statusdesc']);
            }
        });
    };
    return (
        <div className='app__banner py-5 content-box'>
            <div className='container'>
                <div className='px-3'>
                    <h1 className='h1 text-white text-center mb-3'>{t('home.free_download')}</h1>
                    <div className='d-flex justify-content-center'>
                        <a className='app-icon' href='#'>
                            <span className='hide-visually'>iOS app</span>
                            <img
                                src='/images/site/app_store_badge.svg'
                                alt='Available on the App Store'
                            />
                        </a>
                        <a className='app-icon ml-3' href='#'>
                            <span className='hide-visually'>Android App</span>
                            <img
                                src='/images/site/google-play-badge.svg'
                                alt='Available on the App Store'
                            />
                        </a>
                    </div>
                    <form onSubmit={sendLink}>
                        <div className='text-center mt-4'>
                            <h4 className='text-white'>{t('home.enter_mobile_rec')}</h4>
                            <div className='form-inline flex-nowrap justify-content-center align-items-center'>
                                <div className='form-group mx-sm-3 mr-md-0 mb-2'>
                                    <input
                                        type='tel'
                                        required
                                        onChange={changeHandler}
                                        value={mobileprefix + phone}
                                        className='form-control input__radius-custom'
                                        placeholder='91303******'
                                    />
                                </div>
                                <button
                                    type='submit'
                                    disabled={isactive ? true : false}
                                    className='btn btn-primary mb-2 btn__radius-custom'
                                >
                                    {isactive ? t('other.processing') : t('home.send_link')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AppBanner;
