import React, { useState } from 'react';
import { Link } from "react-router-dom";
import SubMenu from './menu'
function Header(props) {
    const [showMenu, SetNav] = useState(false)
    const handleOpen = () => {
        SetNav(!showMenu)
    }

    const handleClose = () => {
        SetNav(!showMenu)
    }
    return (
        <React.Fragment>
            <li className={props.authClass}>
                <Link to="/vendor/dashboard"><i className="icon-home"></i> Dashboard
               </Link>
            </li>
            <li className={props.authClass}>
                <Link to="/vendor/profile"><i className="fa fa-user"></i> Profile
               </Link>
            </li>
            <SubMenu menuname="Order Management">
                <li className="dropdown-item"><Link to="/vendor/new-order"><i className="icon-hourglass"></i> New Order  </Link>
                </li>
                <li className="dropdown-item"><Link to="/vendor/complete-order"><i className="icon-hourglass"></i> Completed Order </Link>
                </li>
                <li className="dropdown-item"><Link to="/vendor/missed-order"><i className="icon-hourglass"></i> Missed Order </Link>
                </li>
            </SubMenu>
            <SubMenu menuname="Employee">
                <li className="dropdown-item"><Link to="/vendor/employee"><i className="icon-hourglass"></i> Employee List  </Link>
                </li>
                <li className="dropdown-item"><Link to="/vendor/add-employee"><i className="icon-hourglass"></i> Add Employee </Link>
                </li>
            </SubMenu>
            <li className={props.authClass}><Link to="/vendor/complains"><i className=" icon-logout "></i> Complains </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/setting"><i className="fa fa-cog"></i> Setting </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/referral-dashboard"><i className=" icon-logout "></i> Referral Dashboard </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/my-videos"><i className=" icon-logout "></i> My Videos </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/change-password"><i className="fa fa-lock"></i> Change Password </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/my-wallet"><i className="fa fa-google-wallet"></i> My Wallet </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/notification"><i className="fa fa-bell"></i> Notification </Link>
            </li>
            <li className={props.authClass}><Link to="/vendor/logout"><i className="fa fa-sign-out"></i> Log out </Link>
            </li>
        </React.Fragment>
    );
}
export default Header;