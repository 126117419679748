import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CustomerNavber from './customerNavbar';
import { LoginContext } from './../../context/loginContext';
import VendorNavber from './vendorNavbar';
import './../../assets/css/chechboxes.css';
import 'react-toastify/dist/ReactToastify.css';
import TopNavbar from './top-navbar';
import AlertModal from '../shared/popModel';
import { useTranslation } from 'react-multi-lang';
import SearchBar from './searchBar';
function Header(props) {
    const myRef = useRef();
    const [isMobile, setMobile] = useState(false);
    const t = useTranslation();
    let showNavClass = 'dropdown-menu user-menu dropdown-menu-right';
    const [showNav, setshowNav] = useState(false);
    const [isAuth] = useContext(LoginContext);
    let userDate = JSON.parse(localStorage.getItem('user_data'));
    const [companyStatus, setCompanyStatus] = useState(
        isAuth['isLogin'] && isAuth['isVendor'] && parseInt(userDate['checkinstatus']) == 1
            ? true
            : false
    );
    const [ismodelvisible, setModelVisible] = useState(
        props.homeData['showpopup'] === '1' ? true : false
    );
    const [isScrolling, setScrolling] = useState(false);

    const scrollListner = (event) => setScrolling(window.scrollY > 20);
    const closemodel = (isvisible) => {
        setModelVisible(isvisible);
    };
    useEffect(() => {
        document.addEventListener('scroll', scrollListner);
        return () => document.removeEventListener('scroll', scrollListner);
    }, []);

    let authClass = !isAuth['isLogin'] ? 'dropdown-item d-none' : 'dropdown-item';
    let authNotClass = isAuth['isLogin'] ? 'dropdown-item d-none' : 'dropdown-item';
    let userName = '';
    if (isAuth['isLogin']) {
        userName = isAuth['isVendor'] ? userDate['outletname'] : userDate['fullname'];
    }
    // Close Dropdown anywhere click
    const handleClickOutside = (e) => {
        if (isAuth['isLogin'] && !myRef.current.contains(e.target)) {
            setshowNav(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.removeEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside, true);
    });

    return (
        <div className='header fixed-top'>
            {!isScrolling ? (
                <TopNavbar
                    sharepagelink={props.homeData['sharepagelink']}
                    phone={props.homeData['jcarecall']}
                    email={props.homeData['jcareemail']}
                    longtext={props.homeData['longtext']}
                />
            ) : null}
            <AlertModal
                popupimage={props.homeData['popupimage']}
                popupmessage={props.homeData['popupmessage']}
                popuptitle={props.homeData['popuptitle']}
                isvisible={ismodelvisible}
                closemodel={closemodel}
            />
            <nav className='navbar navbar-light bg-light navbar-expand-md' role='navigation'>
                <div className='container'>
                    <div className='navbar-identity p-0 d-flex align-items-center justify-content-between w-100'>
                        <Link to='/' className='navbar-brand logo'>
                            <img src='/images/KhidmaNow-logo.svg' height='64' alt='' />
                        </Link>
                        <button
                            data-target='.navbar-collapse'
                            onClick={() => setMobile(!isMobile)}
                            data-toggle='collapse'
                            className='navbar-toggler pull-right collapsed mr-1'
                            type='button'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 30 30'
                                width='30'
                                height='30'
                                focusable='false'
                            >
                                <title>Menu</title>
                                <path
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeMiterlimit='10'
                                    d='M4 7h22M4 15h22M4 23h22'
                                />
                            </svg>
                        </button>
                    </div>
                    <div className={`navbar-collapse collapse ${isMobile ? 'show' : ''}`}>
                        <ul className='nav navbar-nav ml-auto navbar-right flex-nowrap'>
                            <SearchBar></SearchBar>
                            {isAuth['isLogin'] ? (
                                <li
                                    ref={myRef}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setshowNav(!showNav);
                                    }}
                                    className='dropdown no-arrow nav-item'
                                >
                                    <a
                                        href='#'
                                        className='dropdown-toggle nav-link'
                                        data-toggle='dropdown'
                                    >
                                        <span>{userName}</span> <i className='icon-user fa'></i>{' '}
                                        <i className=' icon-down-open-big fa'></i>
                                    </a>
                                    <ul className={showNav ? showNavClass + ' show' : showNavClass}>
                                        <li className={authNotClass}>
                                            <Link to='/login'>
                                                <i className='icon-lock'></i> {t('header.login')}
                                            </Link>
                                        </li>
                                        <li className={authNotClass}>
                                            <Link to='/signup'>
                                                <i className='icon-user'></i> {t('header.signup')}
                                            </Link>
                                        </li>
                                        {isAuth['isLogin'] && isAuth['isVendor'] ? (
                                            <VendorNavber authClassName={authClass}></VendorNavber>
                                        ) : null}
                                        {isAuth['isLogin'] && !isAuth['isVendor'] ? (
                                            <CustomerNavber
                                                authClassName={authClass}
                                            ></CustomerNavber>
                                        ) : null}
                                    </ul>
                                </li>
                            ) : (
                                    <React.Fragment>
                                        <li className='nav-item mr-1'>
                                            <Link
                                                to='/login'
                                                className='btn btn-nav__block mt-2 mt-sm-0 btn-primary'
                                            >
                                                <i className='icon-lock'></i>
                                                {t('header.login')}
                                            </Link>
                                        </li>
                                        <li className='nav-item mr-1'>
                                            <Link
                                                to='/signup'
                                                className='btn btn-nav__block mt-2 mt-sm-0 btn-outline-primary'
                                            >
                                                <i className='icon-user'></i>
                                                {t('header.signup')}
                                            </Link>
                                        </li>
                                    </React.Fragment>
                                )}
                            {/* <li>
                <select
                  className="form-control"
                  onChange={changeLanguage}
                  defaultValue={languageData}
                >
                  <option value="english">English</option>
                  <option value="arabic">Arabic</option>
                </select>
              </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
export default Header;
