import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import { useTranslation, getLanguage } from 'react-multi-lang';
function CustomerReview(props) {
    const t = useTranslation();
    const customerReview = props.customerReviews;
    const customerReviews = customerReview.map((review, index) => (
        <div
            key={index}
            className='m-2  inner col-12 col-sm-6 col-md-4'
            style={{ minWidth: '100%' }}
        >
            <div className='thumbnail content-box p-3'>
                <div className='d-flex'>
                    <div className='text-center'>
                        <img
                            src='/images/shortlogo.png'
                            alt='professional'
                            className='review__img'
                        />
                    </div>
                    <div className='pl-3 text-left w-100'>
                        <div>
                            <strong>{review['outletname']}</strong>
                        </div>
                        <small>{review['servicecategory']}</small>
                        <div className='five-stars-container'>
                            <div className='five-stars'></div>
                        </div>
                        <div className='mt-2'>{review['comment']}</div>
                        <div className='d-flex justify-content-end mt-3 w-100'>
                            {/* <div className=''>{review['customername']}</div> */}
                            <div className='d-flex'>
                                <span className='icon-stopwatch'></span>
                                <span>{review['updatedate']}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    const options = {
        navigation: false,
        pagination: false,
        items: 4,
        rtl: getLanguage() === 'arabic',
        autoplay: true,
        responsive: {
            0: {
                items: 1,
            },
            868: {
                items: 2,
            },
            1368: {
                items: 3,
            },
        },
    };
    return (
        <div className='col-xl-12 content-box '>
            <div className='row row-featured row-featured-category'>
                <div className='col-xl-12  box-title no-border'>
                    <div className='inner'>
                        <h2>{t('home.customer_review')}</h2>
                    </div>
                </div>
                <div className=' relative  content featured-list-row  w100'>
                    <OwlCarousel
                        refrence='car'
                        className='no-margin featured-list-slider owl-carousel owl-theme'
                        options={options}
                    >
                        {customerReviews}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
}
export default CustomerReview;
