import React, { useState, createContext } from "react";
// Create Context of Booking
const VendorContext = createContext();
export default VendorContext
// Create Provider that hold the sharable information
export const VendorProvider = props => {
    const [VendorData, setVendorData] = useState({
        userType: '',
        basicInnfo: [],
        location: [],
        documents: [],
        bankInfo: []
    });
    return (
        <VendorContext.Provider value={[VendorData, setVendorData]}>
            {props.children}
        </VendorContext.Provider>
    );
};
