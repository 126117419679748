import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HomeAction } from './../../store/action/generalAction';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import generalApi from '../../services/generalApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang';
import Geocode from 'react-geocode';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
class Signup extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T,
        };
        this.mobileprefix = this.props.homeData['mobileprefix'];
        Geocode.setApiKey('AIzaSyAfn0186e_Uq5kHupvAXXYrG8ER6kcqP8Y');
        localStorage.removeItem('signupData');
        const { t } = this.props;
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                phone: t('validation.phone'),
                min: t('validation.pinmin'),
                max: t('validation.pinmax'),
                email: t('validation.email'),
                accepted: t('validation.tc')
            },
        });
        this.state = {
            useraddress: '',
            city: 'DXB',
            isactive: false,
            firstname: '',
            lastname: '',
            mobileprefix: this.mobileprefix,
            accepted: true,
            mobile: '',
            mobileerror: '',
            email: '',
            pin: '',
            geolocation: '',
            reference: '',
            locationError: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.getLocation();
    }

    getCity = () => {
        if (this.state.geolocation !== '') {
            // const json = { geolocation: '13.0236752,77.627137' }
            const json = { geolocation: this.state.geolocation };
            generalApi.getData('SSMFetchCity', json).then((response) => {
                console.log(response['data']);
                this.setState({ city: response['data']['cityname'] });
            });
        }
    };
    handleAddress = (address) => {
        this.setState({ ...this.state, useraddress: address });
    };
    handleSelect = (address) => {
        this.setState({ ...this.state, useraddress: address });
        // setLocationData({ ...locationData, address: address });
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                let l = latLng.lat + ',' + latLng.lng;
                this.setState({ ...this.state, geolocation: l });
            })
            .catch((error) => console.error('Error', error));
    };
    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
        } else {
            this.setState({
                locationError: 'Geolocation is not supported by this browser.',
            });
        }
    };
    showPosition = (position) => {
        const L = position.coords.latitude + ',' + position.coords.longitude;
        this.setState({ geolocation: L });
        // this.getCity()
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                this.setState({ useraddress: address });
            },
            (error) => {
                console.error(error);
            }
        );
    };
    showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                this.setState({
                    locationError: 'User denied the request for Geolocation.',
                });
                break;
            case error.POSITION_UNAVAILABLE:
                this.setState({
                    locationError: 'Location information is unavailable.',
                });
                break;
            case error.TIMEOUT:
                this.setState({
                    locationError: 'The request to get user location timed out.',
                });
                break;
            case error.UNKNOWN_ERROR:
                this.setState({
                    locationError: 'An unknown error occurred.',
                });
                break;
            default:
                break;
        }
    };
    handleChange = (e) => {
        if (e.target.name === 'mobile') {
            let mobileprefix = this.mobileprefix;
            let mobile = e.target.value.slice(mobileprefix.length);
            if (mobile.length > 9) {
                mobile = mobile.substring(0, 9);
            }
            const { t } = this.props;
            let error = '';
            if (mobile.length < 9) {
                error = t('validation.phone');
            }
            this.setState({
                ...this.state,
                [e.target.name]: mobile,
                mobileerror: error,
            });
        } else {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            });
        }
        console.log('Checked', this.state);
    };
    submitForm = () => {
        if (this.validator.allValid() && this.state.mobileerror === '') {
            this.setState({ isactive: true });
            let mobileprefix = this.mobileprefix;
            let mobile = mobileprefix + this.state.mobile;
            const json = { mobile: mobile, email: this.state.email };
            generalApi.getData('SSMSendEnrolmentToken', json).then((response) => {
                console.log('Response', response['data']);
                if (response['data']['statuscode'] == '000') {
                    this.setState({ reference: response['data']['referencenumber'] });
                    localStorage.setItem('signupData', JSON.stringify(this.state));
                    if (!this.props.isModel) {
                        this.props.history.push('register-otp');
                    } else {
                        this.props.otpOpen();
                    }
                } else {
                    this.setState({ isactive: false });
                    toast.error(response['data']['statusdesc']);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        const { t } = this.props;

        return (
            <div className={!this.props.isModel ? 'main-container' : ''}>
                <div className='container'>
                    <div className='row mt-3 justify-content-center'>
                        <div
                            className={`
									${this.props.isModel ? 'col-12' : 'col-md-8 '}
								`}
                        >
                            <div className='card card-default signup-box'>
                                {/* <h3 className="text-center">CREATE YOUR ACCOUNT, ITS FREE</h3> */}
                                <div className='panel-intro text-center'>
                                    <img
                                        src='/images/logo.png'
                                        className='page-logo-width'
                                        alt=''
                                    />
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='row'>
                                            <div className='col-6 '>
                                                <label className='control-label'>
                                                    {t('signup.first_name')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='fa fa-user'></i>
                                                    <input
                                                        placeholder={t('signup.first_name')}
                                                        name='firstname'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'first_name'
                                                            )
                                                        }
                                                        value={this.state.firstname}
                                                        className='form-control input-md'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'first_name',
                                                        this.state.firstname,
                                                        'required|max:40'
                                                    )}
                                                </span>
                                            </div>
                                            <div className='col-6'>
                                                <label className='control-label'>
                                                    {t('signup.last_name')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='fa fa-user'></i>
                                                    <input
                                                        required
                                                        name='textinput'
                                                        name='lastname'
                                                        value={this.state.last_name}
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'last_name'
                                                            )
                                                        }
                                                        placeholder={t('signup.last_name')}
                                                        className='form-control input-md'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'last name',
                                                        this.state.lastname,
                                                        'required|max:40'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.phone_no')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='fa fa-phone-square'></i>
                                                    <input
                                                        required
                                                        value={
                                                            this.mobileprefix + this.state.mobile
                                                        }
                                                        name='textinput'
                                                        placeholder={t('signup.phone_no')}
                                                        name='mobile'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'phone_no'
                                                            )
                                                        }
                                                        className='form-control input-md englisg-text'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.state.mobileerror}
                                                    {this.validator.message(
                                                        'phone_no',
                                                        this.state.mobile,
                                                        'required'
                                                    )}
                                                </span>
                                            </div>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.email')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='fa fa-envelope'></i>
                                                    <input
                                                        required
                                                        type='email'
                                                        className='form-control englisg-text'
                                                        name='email'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor('email')
                                                        }
                                                        placeholder={t('signup.email')}
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'email',
                                                        this.state.email,
                                                        'required|email'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.password')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='icon-lock fa'></i>
                                                    <input
                                                        required
                                                        type='password'
                                                        className='form-control englisg-text'
                                                        name='pin'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'password'
                                                            )
                                                        }
                                                        placeholder={t('signup.password')}
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'password',
                                                        this.state.pin,
                                                        'required|min:4|max:4'
                                                    )}
                                                </span>
                                            </div>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.city')}
                                                </label>
                                                <PlacesAutocomplete
                                                    value={this.state.useraddress}
                                                    onChange={this.handleAddress}
                                                    name='city'
                                                    onSelect={this.handleSelect}
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (
                                                            <div className=''>
                                                                <div className='input-icon'>
                                                                    <i className='fa fa-map-marker small__icon'></i>
                                                                    <input
                                                                        {...getInputProps({
                                                                            placeholder:
                                                                                'Enter Location',
                                                                            name: 'city',
                                                                            autoComplete: 'off',
                                                                            className:
                                                                                'englisg-text location-search-input form-control',
                                                                        })}
                                                                    />
                                                                </div>
                                                                <div className='autocomplete-dropdown-container'>
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions.map((suggestion) => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        // inline style for demonstration purpose
                                                                        const style = suggestion.active
                                                                            ? {
                                                                                backgroundColor:
                                                                                    '#fafafa',
                                                                                cursor: 'pointer',
                                                                            }
                                                                            : {
                                                                                backgroundColor:
                                                                                    '#ffffff',
                                                                                cursor: 'pointer',
                                                                            };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(
                                                                                    suggestion,
                                                                                    {
                                                                                        className,
                                                                                        style,
                                                                                    }
                                                                                )}
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        suggestion.description
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                </PlacesAutocomplete>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'city',
                                                        this.state.useraddress,
                                                        'required'
                                                    )}
                                                </span>
                                            </div>
                                            <div className='col-12 ml-3'>
                                                <label className='control-label'>
                                                    <input
                                                        type='checkbox'
                                                        name='accepted'
                                                        className='form-check-input'
                                                        checked={this.state.accepted}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="tc-arabic">{t('signup.agree_tc')}</span>
                                                    <Link to='/privacy-policy' target='_blank'>
                                                        {t('signup.tc')}
                                                    </Link>
                                                </label>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'accepted',
                                                        this.state.accepted,
                                                        'accepted'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='form-group mt-2'>
                                            <button
                                                type='button'
                                                onClick={this.submitForm}
                                                disabled={this.state.isactive ? true : false}
                                                className='btn btn-primary'
                                            >
                                                {this.state.isactive
                                                    ? t('other.processing')
                                                    : t('signup.register')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        homeData: state.generalData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setHomeData: (data) => {
            dispatch(HomeAction(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(Signup)));
