import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Subscribtion from '../../services/setSubscribtion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-multi-lang';
function Footer() {
    const [email, setEmail] = useState('');
    const [isactive, setActive] = useState(false);
    const t = useTranslation();
    function sendLink(event) {
        event.preventDefault();
        setActive(true)
        Subscribtion.sendLink(email).then((res) => {
            setActive(false)
            setEmail('');
            toast.success(res['data']['statusdesc']);
        });
    }
    function handleChange(e) {
        setEmail(e.target.value);
    }

    return (
        <footer className='main-footer mt-2'>
            <ToastContainer />
            <div className='footer-content'>
                <div className='container'>
                    <div className='row'>
                        <div className=' col-xl-2 col-xl-2 col-md-2 col-6  '>
                            <div className='footer-col'>
                                <h4 className='footer-title'>{t('footer.faq')}</h4>
                                <ul className='list-unstyled footer-nav'>
                                    <li>
                                        <Link to='/customer-faq'>{t('footer.customer')}</Link>
                                    </li>
                                    <li>
                                        <Link to='/vendor-faq'>{t('footer.vendor')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className=' col-xl-2 col-xl-2 col-md-2 col-6  '>
                            <div className='footer-col'>
                                <h4 className='footer-title'>{t('footer.tc')}</h4>
                                <ul className='list-unstyled footer-nav'>
                                    <li>
                                        <Link to='/customer-tc'>{t('footer.customer')}</Link>
                                    </li>
                                    <li>
                                        <Link to='/vendor-tc'>{t('footer.vendor')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className=' col-xl-2 col-xl-2 col-md-2 col-6  '>
                            <div className='footer-col'>
                                <h4 className='footer-title'>{t('footer.referral_benifit')}</h4>
                                <ul className='list-unstyled footer-nav'>
                                    <li>
                                        <Link to='/referral-benifit'>
                                            {t('footer.referral_benifit_small')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className=' col-xl-2 col-xl-2 col-md-2 col-6  '>
                            <div className='footer-col'>
                                <h4 className='footer-title'>{t('footer.account')}</h4>
                                <ul className='list-unstyled footer-nav'>
                                    <li>
                                        <Link to='/refund-cancalition'>{t('footer.refund')}</Link>
                                    </li>
                                    <li>
                                        <Link to='/khidmanow-blog'>{t('footer.blog')}</Link>
                                    </li>
                                    <li>
                                        <Link to='/privacy-policy'>{t('footer.privacy')}</Link>
                                    </li>
                                    <li>
                                        <Link to='/contact-us'>{t('footer.contact_us')} </Link>
                                    </li>
                                    <li>
                                        <Link to='/about-us'> {t('footer.about_us')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-xl-4 col-xl-4 col-md-4 col-12'>
                            <div className='footer-col row'>
                                <div className='col-sm-12 col-xs-6 col-xxs-12 no-padding-lg'>
                                    <div className='mobile-app-content'>
                                        <h4 className='footer-title'>{t('footer.mobile_apps')}</h4>
                                        <div className='row '>
                                            <div className='col-6  '>
                                                <a className='app-icon' target="_blank" href='https://apps.apple.com/us/app/khidmanow/id1481348639?ls=1'>
                                                    <span className='hide-visually'>iOS app</span>
                                                    <img
                                                        src='/images/site/app_store_badge.svg'
                                                        alt='Available on the App Store'
                                                    />
                                                </a>
                                            </div>
                                            <div className='col-6  '>
                                                <a className='app-icon' target="_blank" href='https://play.google.com/store/apps/details?id=com.aic.khidmanow'>
                                                    <span className='hide-visually'>
                                                        Android App
                                                    </span>
                                                    <img
                                                        src='/images/site/google-play-badge.svg'
                                                        alt='Available on the App Store'
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <form onSubmit={sendLink}>
                                        <div className='text-center mt-3'>
                                            <p className='text-white arr_text-right'>
                                                {t('footer.new_services')}
                                            </p>
                                            <div className='form-inline justify-content-center align-items-center'>
                                                <div className='form-group mb-2'>
                                                    <input
                                                        type='email'
                                                        required
                                                        onChange={handleChange}
                                                        className='form-control footer__input'
                                                        placeholder={t('footer.email_address')}
                                                    />
                                                </div>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary footer__go mb-2'
                                                >
                                                    {isactive ? t("other.processing") : t("footer.subscribe")}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-sm-12 col-xs-6 col-xxs-12 no-padding-lg'>
                                    <div className='hero-subscribe'>
                                        <h4 className='footer-title no-margin'>
                                            {t('footer.follow_us')}
                                        </h4>
                                        <ul className='list-unstyled list-inline footer-nav social-list-footer social-list-color footer-nav-inline'>
                                            <li>
                                                <a
                                                    className='icon-color fb'
                                                    title='Facebook'
                                                    data-placement='top'
                                                    data-toggle='tooltip'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://www.facebook.com/khidmanow'
                                                >
                                                    <i className='icon-facebook'></i>{' '}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className='icon-color tw'
                                                    title='Twitter'
                                                    data-placement='top'
                                                    data-toggle='tooltip'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://twitter.com/khidmanow'
                                                >
                                                    <i className='icon-twitter'></i>{' '}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className='icon-color lin'
                                                    title='Linkedin'
                                                    data-placement='top'
                                                    data-toggle='tooltip'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://instagram.com/khidmanow'
                                                >
                                                    <i className='icon-instagram-filled'></i>{' '}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>

                        <div className='col-xl-12'>
                            <div className=' text-center paymanet-method-logo'>
                                <img src='/images/site/payment/master_card.png' alt='img' />
                                <img alt='img' src='/images/site/payment/visa_card.png' />
                            </div>

                            <div className='copy-info text-center'>
                                {t('footer.right_reserved')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;
