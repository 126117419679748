import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
function CustomerNavber(props) {
    const [showMenu, SetNav] = useState(false);
    const t = useTranslation();

    const toggleMenu = (event) => {
        event.stopPropagation();

        SetNav(!showMenu);
    };

    return (
        <React.Fragment>
            <li className={props.authClass}>
                <Link to='/user-account'>
                    <i className='fa fa-user-md'></i>
                    {t('header.profile')}
                </Link>
            </li>
            <li className={`${props.authClass} dropdown__parent`} onClick={toggleMenu}>
                <a href='#'>
                    <i className='fa fa-list'></i>
                    {t('header.order')}
                </a>
                <ul
                    className={`dropdown-menu user-menu dropdown-sub-menu ${
                        showMenu ? 'show' : ''
                    }`}
                >
                    <li className='dropdown-item'>
                        <Link to='/current-order'>
                            <i className='fa fa-plus-square-o'></i> {t('header.current_order')}{' '}
                        </Link>
                    </li>
                    <li className='dropdown-item'>
                        <Link to='/order-history'>
                            <i className='fa fa-history'></i> {t('header.order_history')}{' '}
                        </Link>
                    </li>
                </ul>
            </li>
            <li className={props.authClass}>
                <Link to='/password-change'>
                    <i className='fa fa-lock '></i> {t('header.changepassword')}{' '}
                </Link>
            </li>
            <li className={props.authClass}>
                <Link to='/notification'>
                    <i className='fa fa-bell'></i> {t('header.notification')}
                </Link>
            </li>
            <li className={props.authClass}>
                <Link to='/mywallet'>
                    <i className='fa fa-google-wallet '></i> {t('header.mywallet')}
                </Link>
            </li>
            <li className={props.authClass}>
                <Link to='/ref-dashboard'>
                    <i className='fa fa-share '></i> {t('header.refdashboard')}
                </Link>
            </li>
            <li className={props.authClass}>
                <Link to='/logout'>
                    <i className='fa fa-sign-out'></i> {t('header.logout')}{' '}
                </Link>
            </li>
        </React.Fragment>
    );
}
export default CustomerNavber;
