import React, { Suspense, useContext, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { BookingProvider } from './../context/bookingContext';
import { CheckoutProvider } from './../context/checkoutContext';
import { VendorProvider } from './../context/vendorSignupData';
import { OrderProvider } from './../context/orderStatus';
import { LoginContext } from './../context/loginContext';
import { LanguageProvider } from './../context/languageContext';
import Header from '../component/template/header';
import Footer from '../component/template/footer';
import Home from '../container/home';
import Login from '../container/account/login';
import Logout from '../container/account/logout';
import ForgetOtp from '../container/account/forgetOtp';
import ResetPassword from '../container/account/resetPin';
import Signup from '../container/account/signup';
import RegisterOtp from '../container/account/rgisterOtp';
import ChangePassword from '../container/account/changePassword';
import Profile from '../container/account/profile';
import MyWallet from '../container/myWallet';
import RefDashboard from '../container/refDashboard';
import { PageLoader } from './../component/shared/loader';

// Paytabs
import ReferralHandler from '../component/shared/refferalHandler';
import VendorReferralHandler from '../component/shared/VendorReferralHandler';
import Paytabs from '../component/order/paytm';
import Notification from '../container/notification';
import CustomerTC from '../container/static/customerTC';
import VendorTC from '../container/static/vendorTC';
import PrivacyPolicy from '../container/static/privacyPolicy';
import RC from '../container/static/refundCancalition';
import AboutUs from '../container/static/aboutUs';
import ContactUs from '../container/static/contactUs';
import RB from '../container/static/referralBenifit';
import CFAQ from '../container/static/customerFAQ';
import VFAQ from '../container/static/vendorFAQ';
import Blog from '../container/static/blog';
//
const ServicesList = React.lazy(() => import('./../container/services/servicesList'));
const ServicesDetails = React.lazy(() => import('./../container/services/servicesDetails'));
const BookService = React.lazy(() => import('./../container/services/bookService'));
const CurrentOrder = React.lazy(() => import('../container/order/currentOrder'));
const PaytmResponse = React.lazy(() => import('../container/order/paytemResponse'));
const OrderHistory = React.lazy(() => import('../container/order/orderHistory'));
const VendorLogin = React.lazy(() => import('./../container/vendor/account/login'));
const VendorForgetOtp = React.lazy(() => import('./../container/vendor/account/forgotOtp'));
const VendorResetPin = React.lazy(() => import('./../container/vendor/account/resetPin'));
const VendorSignup = React.lazy(() => import('./../container/vendor/account/signup'));
const VendorLogout = React.lazy(() => import('./../container/vendor/account/logout'));
const VendorRegMsg = React.lazy(() => import('./../container/messages/vendorRegMsg'));
const NewOrder = React.lazy(() => import('./../container/vendor/order/newOrder'));
const CompleteOrder = React.lazy(() => import('./../container/vendor/order/completedOrder'));
const MissedOrder = React.lazy(() => import('./../container/vendor/order/missedOrder'));
const OrderDetails = React.lazy(() => import('./../container/vendor/order/orderDetails'));
const CardPayment = React.lazy(() => import('./../component/vendor/payment/cardPayment'));
const Complains = React.lazy(() => import('./../container/vendor/complains'));
const Dashboard = React.lazy(() => import('./../container/vendor/dashboard'));
const VendorProfile = React.lazy(() => import('./../container/vendor/account/profile'));
const VendorEmployee = React.lazy(() => import('./../container/vendor/employee/employeeList'));
const VendorChangePassword = React.lazy(() => import('./../container/vendor/changePassword'));
const VendorAddEmployee = React.lazy(() => import('./../container/vendor/employee/addEmployee'));
const VendorUpdateEmployee = React.lazy(() =>
    import('./../container/vendor/employee/updateEmployee')
);
const ReffralDashboard = React.lazy(() => import('./../container/vendor/referralDashboard'));
const VendorNotification = React.lazy(() => import('./../container/vendor/notification'));
const VendorMyWallet = React.lazy(() => import('./../container/vendor/myWallet'));
const MyVideo = React.lazy(() => import('./../container/vendor/myVideo'));
const Setting = React.lazy(() => import('./../container/vendor/setting'));

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const HomeRediector = () => {
    const { pathname } = useLocation();
    const { replace } = useHistory();

    useEffect(() => {
        const hasLoaded = sessionStorage.getItem('hasLoaded');
        if (!Boolean(hasLoaded) && !(pathname === '/' || pathname === '/home')) {
            sessionStorage.setItem('hasLoaded', 'true');
            replace('/');
        }
    }, []);

    return null;
};

function Routes(props) {
    const homeData = props.homeData;
    return (
        <Router>
            <ScrollToTop />
            <HomeRediector />
            <LanguageProvider>
                <Header homeData={homeData}></Header>
            </LanguageProvider>
            <Switch>
                <Route path='/message'>
                    <Suspense fallback={PageLoader}>
                        <VendorRegMsg />
                    </Suspense>
                </Route>
                <LoginRoute path='/login'>
                    <Login />
                </LoginRoute>
                <LoginRoute path='/forgot-otp'>
                    <ForgetOtp />
                </LoginRoute>
                <LoginRoute path='/reset-password'>
                    <ResetPassword />
                </LoginRoute>
                <LoginRoute path='/signup'>
                    <Signup />
                </LoginRoute>
                <LoginRoute path='/register-otp'>
                    <RegisterOtp />
                </LoginRoute>
                <PrivateRoute path='/logout'>
                    <Logout />
                </PrivateRoute>
                <PrivateRoute path='/password-change'>
                    <ChangePassword />
                </PrivateRoute>
                <PrivateRoute path='/user-account'>
                    <Profile />
                </PrivateRoute>
                <LoginRoute path='/vendor/login'>
                    <Suspense fallback={PageLoader}>
                        <VendorLogin mobileprefix={homeData['mobileprefix']} />
                    </Suspense>
                </LoginRoute>
                <LoginRoute path='/vendor/signup'>
                    <VendorProvider>
                        <Suspense fallback={PageLoader}>
                            <VendorSignup mobileprefix={homeData['mobileprefix']} />
                        </Suspense>
                    </VendorProvider>
                </LoginRoute>
                <PrivateVendorRoute path='/vendor/logout'>
                    <Suspense fallback={PageLoader}>
                        <VendorLogout />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/dashboard'>
                    <Suspense fallback={PageLoader}>
                        <Dashboard />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/profile'>
                    <VendorProvider>
                        <Suspense fallback={PageLoader}>
                            <VendorProfile mobileprefix={homeData['mobileprefix']} />
                        </Suspense>
                    </VendorProvider>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/order-details/:id'>
                    <OrderProvider>
                        <Suspense fallback={PageLoader}>
                            <OrderDetails />
                        </Suspense>
                    </OrderProvider>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/card-payment/:id'>
                    <Suspense fallback={PageLoader}>
                        <CardPayment />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/change-password'>
                    <Suspense fallback={PageLoader}>
                        <VendorChangePassword />
                    </Suspense>
                </PrivateVendorRoute>

                <PrivateVendorRoute path='/vendor/new-order'>
                    <OrderProvider>
                        <Suspense fallback={PageLoader}>
                            <NewOrder />
                        </Suspense>
                    </OrderProvider>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/complete-order'>
                    <Suspense fallback={PageLoader}>
                        <CompleteOrder />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/missed-order'>
                    <Suspense fallback={PageLoader}>
                        <MissedOrder />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/complains'>
                    <Suspense fallback={PageLoader}>
                        <Complains />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/employee'>
                    <Suspense fallback={PageLoader}>
                        <VendorEmployee />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/add-employee'>
                    <Suspense fallback={PageLoader}>
                        <VendorAddEmployee />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/update-employee/:id'>
                    <Suspense fallback={PageLoader}>
                        <VendorUpdateEmployee />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/setting'>
                    <Suspense fallback={PageLoader}>
                        <Setting />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/referral-dashboard'>
                    <Suspense fallback={PageLoader}>
                        <ReffralDashboard />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/my-wallet'>
                    <Suspense fallback={PageLoader}>
                        <VendorMyWallet />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/notification'>
                    <Suspense fallback={PageLoader}>
                        <VendorNotification />
                    </Suspense>
                </PrivateVendorRoute>
                <PrivateVendorRoute path='/vendor/my-videos'>
                    <Suspense fallback={PageLoader}>
                        <MyVideo />
                    </Suspense>
                </PrivateVendorRoute>
                <LoginRoute path='/vendor/forget-otp'>
                    <Suspense fallback={PageLoader}>
                        <VendorForgetOtp />
                    </Suspense>
                </LoginRoute>
                <LoginRoute path='/vendor/reset-pin'>
                    <Suspense fallback={PageLoader}>
                        <VendorResetPin />
                    </Suspense>
                </LoginRoute>
                <PrivateRoute path='/current-order'>
                    <Suspense fallback={PageLoader}>
                        <CurrentOrder />
                    </Suspense>
                </PrivateRoute>
                <PrivateRoute path='/notification'>
                    <Notification />
                </PrivateRoute>
                <PrivateRoute path='/mywallet'>
                    <MyWallet />
                </PrivateRoute>
                <PrivateRoute path='/ref-dashboard'>
                    <RefDashboard />
                </PrivateRoute>
                <PrivateRoute path='/payment_status/:id'>
                    <Suspense fallback={PageLoader}>
                        <PaytmResponse />
                    </Suspense>
                </PrivateRoute>
                <PrivateRoute path='/order-history'>
                    <Suspense fallback={PageLoader}>
                        <OrderHistory />
                    </Suspense>
                </PrivateRoute>
                <PrivateRoute path='/payment-res/:status/:bankcode'>
                    <Paytabs />
                </PrivateRoute>
                <Route path='/customer-tc'>
                    <CustomerTC />
                </Route>
                <Route path='/vendor-tc'>
                    <VendorTC />
                </Route>
                <Route path='/privacy-policy'>
                    <PrivacyPolicy />
                </Route>
                <Route path='/referral-benifit'>
                    <RB />
                </Route>
                <Route path='/customer-faq'>
                    <CFAQ />
                </Route>
                <Route path='/vendor-faq'>
                    <VFAQ />
                </Route>
                <Route path='/khidmanow-blog'>
                    <Blog />
                </Route>
                <Route path='/refund-cancalition'>
                    <RC />
                </Route>
                <Route path='/about-us'>
                    <AboutUs />
                </Route>
                <Route path='/contact-us'>
                    <ContactUs />
                </Route>
                <Route path='/referral'>
                    <ReferralHandler />
                </Route>
                <Route path='/vendor/referral'>
                    <VendorReferralHandler />
                </Route>
                <Route path='/item-services/:id'>
                    <Suspense fallback={PageLoader}>
                        <ServicesList />
                    </Suspense>
                </Route>
                <Route path='/service-details/:id'>
                    <Suspense fallback={PageLoader}>
                        <ServicesDetails />
                    </Suspense>
                </Route>
                <Route path='/service-book/:id'>
                    <BookingProvider>
                        <CheckoutProvider>
                            <Suspense fallback={PageLoader}>
                                <BookService />
                            </Suspense>
                        </CheckoutProvider>
                    </BookingProvider>
                </Route>
                <Route path='/'>
                    <Home homeData={homeData}></Home>
                </Route>
            </Switch>
            <Footer></Footer>
        </Router>
    );
}

function PrivateVendorRoute({ children, ...rest }) {
    const [isAuth, setLoginUser] = useContext(LoginContext);
    let isAuthenticated = isAuth['isLogin'];
    let isVendor = isAuth['isVendor'];
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated && isVendor ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function PrivateRoute({ children, ...rest }) {
    const [isAuth, setLoginUser] = useContext(LoginContext);
    let isAuthenticated = isAuth['isLogin'];
    let isVendor = isAuth['isVendor'];
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated && !isVendor ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function LoginRoute({ children, ...rest }) {
    const [isAuth, setLoginUser] = useContext(LoginContext);
    let isAuthenticated = isAuth['isLogin'];
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
export default Routes;
