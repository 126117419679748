import React, { useState, useEffect } from 'react';
import generalApi from './../services/generalApi';
import Loader from './../component/shared/loader';
import NotificationC from './../component/shared/notificationC';
import { useTranslation } from 'react-multi-lang'
import InfiniteScroll from 'react-infinite-scroll-component';
function Notification() {
    const t = useTranslation()
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState([]);
    const [allnotification, setAllNotification] = useState([]);

    let counter = 10;
    const [itemcounter, setCounter] = useState(counter);
    const [hasMore, sethasMore] = useState(true);
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        const json = {
            customer: userData['customerid'],
            certificate: userData['certificate'],
        };
        generalApi.getData('SSMarchiveHistoryCustomer', json).then((response) => {
            console.log('setVideo', response['data']);
            let notifications = response['data'].customermessages
            setAllNotification(notifications);
            let temp = notifications.slice(0, itemcounter);
            setNotification(temp);
            setLoading(false);
        });
    }, []);
    const fetchMoreData = () => {
        if (notification.length >= allnotification.length) {
            sethasMore(false);
            return;
        }
        // a fake async api call like which sends
        // 20 more records in .5 secs
        setTimeout(() => {
            let addcouneter = itemcounter + counter
            setCounter(addcouneter);
            let temp = allnotification.slice(itemcounter, addcouneter);
            setNotification(notification.concat(temp))
        }, 500);
    }
    if (loading) {
        return <Loader />;
    }
    return (
        <div className='main-container'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 notification__container'>
                        <h2 className='notification__heading text-right-arabic'>{t('other.notification')}</h2>
                        {notification.length > 0 ?
                            <InfiniteScroll
                                dataLength={notification.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<Loader />}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>{t('message.all_seen')}</b>
                                    </p>
                                }
                            >
                                {notification.map((info, i) => (
                                    <NotificationC notificationinfo={info} key={i}></NotificationC>
                                ))}
                            </InfiniteScroll>
                            : (
                                <div className='no__notification'>
                                    <h3>{t('other.no_noti_yet')}</h3>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Notification;
