import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
const Jumbotron = () => {
    const t = useTranslation();
    return (
        <div className="container container-ver2">
            <div className="content-box service-provider">
                <div className=" service__provider-jumbotron">
                    <div className="container text-center dflex">
                        <h3>{t("home.service_provider")}</h3>
                    </div>
                    <div className="text-center buttons d-flex justify-content-center">
                        <Link to="/vendor/login" className="btn vendor-login btn-block btn-nav__block mt-2 mt-sm-0 btn-primary ml-0">
                            {t("home.login")}
                        </Link>
                        <Link to="/vendor/signup" className="btn vendor-login btn-block ml-1 btn-nav__block mt-2 mt-sm-0 btn-outline-primary">
                            {t("home.signup")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Jumbotron;
