import { HOME_PAGE, SIGN_UP } from "../actionConstant";
const generalData = (state = {}, action) => {
  switch (action.type) {
    case HOME_PAGE:
      state = action.payload;
      break;
  }
  console.log("Home Red", state);
  return state;
};
export default generalData;
