import { HOME_PAGE, SIGN_UP } from "../actionConstant";
export function HomeAction(data) {
  return {
    type: HOME_PAGE,
    payload: data
  };
}
export function SignUpAction(data) {
  return {
    type: SIGN_UP,
    payload: data
  };
}
