import React from 'react';
import Modal from './../shared/model';

const AlertModal = ({ isvisible, closemodel, popupimage, popuptitle, popupmessage }) => {
    return (
        <Modal isOpen={isvisible}>
            <div className='modal-header'>
                <h4 className='modal-title'>{popuptitle}</h4>
                <button
                    type='button'
                    onClick={() => closemodel(!isvisible)}
                    className='close'
                    data-dismiss='modal'
                >
                    &times;
                </button>
            </div>
            <div className='modal-body'>
                <div className='text-center'>
                    <img className='modal__img' src={popupimage} alt='Alert' />
                </div>

                <div className='modal__text'>
                    <p>{popupmessage}</p>
                </div>
            </div>
        </Modal>
    );
};

export default AlertModal;
