import React, { useState, createContext } from "react";
import { IsLogin } from './../config/auth'
// Create Context of Booking
export const BookingContext = createContext();

// Create Provider that hold the sharable information
export const BookingProvider = props => {
  const [bookingData, setBookingData] = useState({
    validateComponent: {
      fr_book_multionly: true,
      fr_hourpage: true,
      fr_book_addinfo: true,
      fr_book_time: true,
      fr_qapage1: true,
      fr_book_vendorlocation: true,
      fr_book_address: true,
      fr_book_toaddress: true,
      finalpage: true,
      fr_book_qty: true,
      fr_book_addinfotext: true,
      is_login: IsLogin()
    },
    multichoice: {},
    multichoiceorder: {},
    itmmltqus: "",
    totalHour: {},
    questionAnswer: {},
    additionalInfo: [],
    locationId: "",
    locationName: "",
    geolocation: "",
    bookingQuantity: 1,
    landMark: '',
    useraddress: "",
    locationTo: { cityName: '', latLng: '', landMark: '' },
    bookingInfo: {},
    itemDetails: {}
  });
  return (
    <BookingContext.Provider value={[bookingData, setBookingData]}>
      {props.children}
    </BookingContext.Provider>
  );
};
