import React, { useState, useEffect } from 'react';
import generalApi from './../services/generalApi';
import Loader from './../component/shared/loader';
import { useTranslation } from 'react-multi-lang';
import { to_float } from './../config/helper';
import InfiniteScroll from 'react-infinite-scroll-component';
function MyWallet() {
    const t = useTranslation();
    const [loading, setLoading] = useState(true);
    const [statment, setStatement] = useState([]);
    const [balance, setBalance] = useState(0);
    const [Allstatment, setAllStatement] = useState([]);
    let counter = 10;
    const [itemcounter, setCounter] = useState(counter);
    const [hasMore, sethasMore] = useState(true);
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        const json = {
            customer: userData['customerid'],
            certificate: userData['certificate'],
        };
        generalApi.getData('SSMWalletStatement', json).then((response) => {
            setBalance(response['data']['balance']);
            console.log('statement', response['data']);

            setAllStatement(response['data']['walletstatement']);
            let temp = response['data']['walletstatement'].slice(0, itemcounter);
            setStatement(temp);
            setLoading(false);
        });
    }, []);
    const fetchMoreData = () => {
        if (statment.length >= Allstatment.length) {
            sethasMore(false);
            return;
        }
        // a fake async api call like which sends
        // 20 more records in .5 secs
        setTimeout(() => {
            let addcouneter = itemcounter + counter;
            setCounter(addcouneter);
            let temp = Allstatment.slice(itemcounter, addcouneter);
            setStatement(statment.concat(temp));
        }, 500);
    };
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div className='main-container'>
            <div className='container'>
                <div className='row mx-0'>
                    <div className='col-md-3 mx-auto content-box'>
                        <div className='card'>
                            <div className='card-body text-center'>
                                <i className='iconsminds-clock'></i>
                                <p className='font-weight-bold f-17 mb-0'>{t('order.balance')}</p>
                                <p className='lead text-center'>
                                    {t('other.AED')} {to_float(balance)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='wallet-statement'>
                    <InfiniteScroll
                        dataLength={statment.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Loader />}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        <table className='table '>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('order.member_name')}</th>
                                    <th>{t('order.narration')}</th>
                                    <th>{t('order.amount')}</th>
                                    <th>{t('order.rembalance')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Allstatment.length > 0
                                    ? statment.map((info, i) => (
                                        <tr
                                            key={info['slno']}
                                            className={i % 2 == 0 ? 'bg-light-blue' : 'bg-white'}
                                        >
                                            <td>{info['slno']}</td>
                                            <td>{info['membername']}</td>
                                            <td>{info['narration1']}</td>
                                            <td>{to_float(info['amount'])}</td>
                                            <td>{to_float(info['runningbalance'])}</td>
                                        </tr>
                                    ))
                                    : 'null'}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}
export default MyWallet;
