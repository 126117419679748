import React, { useEffect, useState } from 'react';
import generalApi from '../../services/generalApi';
import { stripHtml } from '../../config/helper';
import Accordion from '../../component/shared/accordian';
import './faqStyle.css';
import SubAccordion from '../../component/shared/sub-accordian';
import Loader from './../../component/shared/loader';
import { useTranslation } from 'react-multi-lang';
function CustomerFAQ() {
    const t = useTranslation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const json = { category: 'C' };
        generalApi.getData('SSMfaqGroupList', json).then((response) => {
            setData(response['data']['faqlist']);

            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div className='container main-container mb-5'>
            <h1 className='text-center mt-2'>{t('static.c_faq')} </h1>
            <div>
                {data.map((faq, i) => (
                    <Accordion heading={faq.groupnamename} key={faq.groupcode}>
                        <ul className='list-style-arrow'>
                            {faq['groupQA'].map((s_faq, s_i) => (
                                <SubAccordion
                                    key={s_i}
                                    question={s_faq['question']}
                                    answer={s_faq['answer']}
                                />
                            ))}
                        </ul>
                    </Accordion>
                ))}
            </div>
        </div>
    );
}
export default CustomerFAQ;
