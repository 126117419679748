import api from "./api";
const appLink = {
  sendLink: email =>
    api("SSMNewsletterSubscription", {
      data: {
        email: email
      }
    })
};
export default appLink;
