import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import DummyImage from './../shared/dummyImage';
import Arrow from './Arrow';

function HomeSlider(props) {
    const sliderImages = props.sliderData;
    const slideImages = sliderImages.map((slideImage, index) => (
        <div key={index} className='sliders'>
            <DummyImage classname="slider-img" src={slideImage['imageurllWEB']} />
        </div>
    ));
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        arrows: true,
        lazyLoad: 'ondemand',
        cssEase: 'linear',
        fade: true,
        nextArrow: <Arrow arrowType='right' arrow='angle-right' />,
        prevArrow: <Arrow arrowType='left' arrow='angle-left' />,
    };
    return (
        <div className='intro'>
            <div className='slider__section'>
                <Slider {...settings}>{slideImages}</Slider>

                {/* <div className="search__section">
          <div className="search__input">
            <input type="search" placeholder="Search" />
            <button className="btn btn-primary">
              <i className="icon-search fa"></i>
            </button>
          </div>
        </div> */}
            </div>
        </div>
    );
}
export default HomeSlider;
