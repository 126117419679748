import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import generalApi from '../../services/generalApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Geocode from 'react-geocode';
import { T, withTranslation } from 'react-multi-lang';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
class Profile extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T,
        };
        Geocode.setApiKey('AIzaSyAfn0186e_Uq5kHupvAXXYrG8ER6kcqP8Y');
        this.userdata = JSON.parse(localStorage.getItem('user_data'));
        const { t } = this.props;
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                phone: t('validation.phone'),
                min: t('validation.pinmin'),
                max: t('validation.pinmax'),
                email: t('validation.email'),
            },
        });
        this.state = {
            isactive: false,
            firstname: this.userdata.firstname,
            gender: this.userdata.gender,
            lastname: this.userdata.lastname,
            email: this.userdata.emailid,
            mobile: this.userdata.mobilenumber,
            geolocation: this.userdata.userlat + ',' + this.userdata.userlon,
            useraddress: this.userdata.areaname,
            reference: '',
            locationError: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleAddress = (address) => {
        this.setState({ ...this.state, useraddress: address });
    };
    handleSelect = (address) => {
        this.setState({ ...this.state, useraddress: address });
        // setLocationData({ ...locationData, address: address });
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                let l = latLng.lat + ',' + latLng.lng;
                this.setState({ ...this.state, geolocation: l });
            })
            .catch((error) => console.error('Error', error));
    };
    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        });
        console.log('Checked', e.target.checked);
    };
    submitForm = () => {
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            const json = {
                email: this.state.email,
                certificate: this.userdata['certificate'],
                customer: this.userdata['customerid'],
                geolocation: this.state.geolocation,
                address: this.state.useraddress,
                gender: this.state.gender,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                fbtoken: localStorage.getItem('fcm-token'),
            };
            generalApi.getData('SSMUpdateCustomerProfile', json).then((response) => {
                if (response['data']['statuscode'] == '000') {
                    this.userdata['gender'] = this.state.gender;
                    this.userdata['areaname'] = this.state.useraddress;
                    this.userdata['geolocation'] = this.state.geolocation;
                    this.userdata['emailid'] = this.state.email;
                    this.setState({ isactive: false });
                    localStorage.setItem('user_data', JSON.stringify(this.userdata));
                    const { t } = this.props;
                    let message = {
                        heading: t('message.success'),
                        des: t('message.profilemessage'),
                        link: '/',
                        linkname: t('message.home'),
                    };
                    localStorage.setItem('message', JSON.stringify(message));
                    this.props.history.push('/message');
                } else {
                    this.setState({ isactive: false });
                    toast.error(response['data']['statusdesc']);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        const { t } = this.props;
        return (
            <div className='main-container'>
                <div className='container'>
                    <div className='row mt-3 justify-content-center'>
                        <div className='col-12 col-md-8'>
                            <div className='card card-default signup-box'>
                                <h3 className='text-center mt-4'>{t('signup.update_profile')}</h3>
                                <div className='card-body'>
                                    <form>
                                        <div className='row'>
                                            <div className='col-12 col-md-6'>
                                                <label className='control-label'>
                                                    {t('signup.first_name')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='icon-person fa'></i>
                                                    <input
                                                        placeholder={t('signup.first_name')}
                                                        readOnly
                                                        value={this.state.firstname}
                                                        name='firstname'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'first_name'
                                                            )
                                                        }
                                                        className='form-control input-md'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'first_name',
                                                        this.state.firstname,
                                                        'required'
                                                    )}
                                                </span>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <label className='control-label'>
                                                    {t('signup.last_name')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='icon-person fa'></i>
                                                    <input
                                                        required
                                                        name='lastname'
                                                        readOnly
                                                        value={this.state.lastname}
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'last_name'
                                                            )
                                                        }
                                                        placeholder={t('signup.last_name')}
                                                        className='form-control input-md'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'last name',
                                                        this.state.lastname,
                                                        'required'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.phone_no')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='fa fa-phone-square'></i>
                                                    <input
                                                        required
                                                        value={this.state.mobile}
                                                        readOnly
                                                        placeholder={t('signup.phone_no')}
                                                        name='mobile'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'phone_no'
                                                            )
                                                        }
                                                        className='form-control input-md englisg-text'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'phone_no',
                                                        this.state.mobile,
                                                        'required|phone'
                                                    )}
                                                </span>
                                            </div>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.email')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='fa fa-envelope'></i>
                                                    <input
                                                        required
                                                        value={this.state.email}
                                                        type='email'
                                                        className='form-control englisg-text'
                                                        name='email'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor('email')
                                                        }
                                                        placeholder={t('signup.email')}
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'email',
                                                        this.state.email,
                                                        'required|email'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.gender')}
                                                    <sup>*</sup>
                                                </label>
                                                <div className='d-flex flex-column flex-md-row'>
                                                    <label className='control font-weight-regular gender__radio control--radio mb-0 mr-1'>
                                                        {t('signup.male')}
                                                        <input
                                                            type='radio'
                                                            name='gender'
                                                            value='1'
                                                            onChange={this.handleChange}
                                                            checked={
                                                                this.state.gender === '1'
                                                                    ? true
                                                                    : false
                                                            }
                                                            className='form-check-input'
                                                        />
                                                        <div className='control__indicator'></div>
                                                    </label>
                                                    <label className='control font-weight-regular gender__radio control--radio mb-0 mr-1'>
                                                        {t('signup.fmale')}
                                                        <input
                                                            type='radio'
                                                            name='gender'
                                                            value='2'
                                                            onChange={this.handleChange}
                                                            checked={
                                                                this.state.gender === '2'
                                                                    ? true
                                                                    : false
                                                            }
                                                            className='form-check-input'
                                                        />
                                                        <div className='control__indicator'></div>
                                                    </label>
                                                    <label className='control font-weight-regular gender__radio control--radio mb-0 mr-1'>
                                                        {t('signup.not_dis')}
                                                        <input
                                                            type='radio'
                                                            name='gender'
                                                            value='3'
                                                            onChange={this.handleChange}
                                                            checked={
                                                                this.state.gender === '3'
                                                                    ? true
                                                                    : false
                                                            }
                                                            className='form-check-input'
                                                        />
                                                        <div className='control__indicator'></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <label className='control-label'>
                                                    {t('signup.city')}
                                                </label>
                                                <PlacesAutocomplete
                                                    value={this.state.useraddress}
                                                    onChange={this.handleAddress}
                                                    name='city'
                                                    onSelect={this.handleSelect}
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (
                                                        <div className=''>
                                                            <div className='input-icon'>
                                                                <i className='fa fa-map-marker '></i>

                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder:
                                                                            'Enter Location',
                                                                        name: 'city',
                                                                        autoComplete: 'off',
                                                                        className:
                                                                            'englisg-text location-search-input form-control',
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className='autocomplete-dropdown-container'>
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map((suggestion) => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    // inline style for demonstration purpose
                                                                    const style = suggestion.active
                                                                        ? {
                                                                              backgroundColor:
                                                                                  '#fafafa',
                                                                              cursor: 'pointer',
                                                                          }
                                                                        : {
                                                                              backgroundColor:
                                                                                  '#ffffff',
                                                                              cursor: 'pointer',
                                                                          };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(
                                                                                suggestion,
                                                                                {
                                                                                    className,
                                                                                    style,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    suggestion.description
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'city',
                                                        this.state.useraddress,
                                                        'required'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='form-group mt-2'>
                                            <button
                                                type='button'
                                                onClick={this.submitForm}
                                                disabled={this.state.isactive ? true : false}
                                                className='btn btn-primary'
                                            >
                                                {this.state.isactive
                                                    ? t('reset_pin.processing')
                                                    : t('signup.update')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation(Profile));
