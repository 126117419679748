import React from "react";
import { useEffect } from "react";

const Modal = ({ isOpen, onClose, children, classes = "" }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }

        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [isOpen]);

    return (
        <div
            className={`modal fade ${isOpen ? "in" : ""} ${classes}`}
            style={{ display: isOpen ? "block" : "none", paddingRight: isOpen ? "15px" : "0" }}
            role="dialog"
        >
            <div className="modal-dialog">
                <div className="modal-content">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
