import React from "react";

const Arrow = ({ arrow, arrowType = "right", onClick }) => {
    return (
        <div className={`arrow raashan-bcolor text-white ${arrowType}`} onClick={onClick}>
            <i className={`fa fa-${arrow}`} />
        </div>
    );
};

export default Arrow;
