import React, { useState } from 'react'
function Notification(props) {
    const [close, setClose] = useState(true);
    return (
        <div className={`alert alert-danger alert-dismissible fade ${close ? 'show' : 'hide'}`} role="alert">
            {props.msg}
            <button type="button" onClick={() => setClose(!close)} className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )

}
export default Notification