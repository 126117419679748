import React, { useState } from 'react'
import { useTranslation } from 'react-multi-lang';
function Blog() {
    const t = useTranslation();
    const [iframeHight, setiframeHight] = useState("338vh");

    return (
        <div className="main-container">
            <div className="container">
                <h1 className="text-center mt-2">{t('static.blog')} </h1>
                <iframe scrolling="no" style={{ width: '100%', height: iframeHight }} src="https://khidmanow.blogspot.com/" ></iframe>
            </div >
        </div>
    );
}
export default Blog;