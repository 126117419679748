import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/customStyle.css';
import './assets/css/arabic.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';
import { LanguageProvider } from './context/languageContext';
import english from './translation/english.json';
import arabic from './translation/arabic.json';
import ErrorBoundary from './ErrorBoundary';
setDefaultTranslations({ arabic, english });
let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'english';
setDefaultLanguage(language);
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//         .register('./firebase-messaging-sw.js')
//         .then(function (registration) {
//             console.log('Registration successful, scope is:', registration.scope);
//         })
//         .catch(function (err) {
//             console.log('Service worker registration failed, error:', err);
//         });
// }

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <LanguageProvider>
                <App />
            </LanguageProvider>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
