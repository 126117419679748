import React, { useContext, useEffect, useRef, useState } from 'react';
import generalApi from './../../services/generalApi';
import { LoginContext } from './../../context/loginContext';
import { LanguageContext } from './../../context/languageContext';
import { useTranslation } from 'react-multi-lang';
import ShareWebsite from './shareWebsite';
const TopNavbar = (props) => {
    // Reference
    const myRef = useRef();
    const [isAuth] = useContext(LoginContext);
    let userData = JSON.parse(localStorage.getItem('user_data'));
    const [companyStatus, setCompanyStatus] = useState(
        isAuth['isLogin'] && isAuth['isVendor'] && parseInt(userData['checkinstatus']) === 1
            ? true
            : false
    );
    const [language, setLanguageData] = useContext(LanguageContext);
    const [showflag, setShowFlag] = useState(false);
    const t = useTranslation();

    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            setShowFlag(false);
        }
    };
    useEffect(() => {
        let status =
            isAuth['isLogin'] && isAuth['isVendor'] && parseInt(userData['checkinstatus']) === 1
                ? true
                : false;
        setCompanyStatus(status);
    }, [isAuth]);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.removeEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside, true);
    });
    const changeLanguage = (languaget) => {
        localStorage.setItem('language', languaget);
        // setLanguage(languaget);
        setLanguageData(languaget);
        let userData = JSON.parse(localStorage.getItem('user_data'));
        if (!userData) {
            window.location.reload();
        }
        if (languaget !== language) {
            languagechange(languaget);
        }
    };
    const languagechange = (languaget) => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        const json = {
            customer: userData['customerid'],
            certificate: userData['certificate'],
            language: languaget === 'arabic' ? 'A' : 'E',
        };
        generalApi.getData('SSMChangeLanguage', json).then((response) => {
            window.location.reload();
        });
    };
    const handleChange = () => {
        setCompanyStatus(!companyStatus);
        updateCompanyStatus(!companyStatus);
    };
    const updateCompanyStatus = (companyStatus) => {
        const json = {
            certificate: userData['certificate'],
            employeeid: userData['employeeid'],
            outlet: userData['outletid'],
            status: companyStatus ? 1 : 0,
        };
        generalApi.getData('SSMCompanyCheckInOut', json).then((response) => {
            console.log('Company status', response['data']);
            if (response['data']['statuscode'] == '000') {
                userData['checkinstatus'] = response['data']['checkinstatus'];
                localStorage.setItem('user_data', JSON.stringify(userData));
                // toast.success('Successfully update the status');
            } else {
                // toast.error(response['data']['statusdesc']);
                // props.history.push('/vendor/new-order')
            }
        });
    };
    return (
        <div className='topnavbar border-bottom py-2'>
            <div className='container'>
                <div className='top__navbar'>
                    <div className='contact__info'>
                        <div className='phone'>
                            <img src='/images/icon-phone-header.png.png' alt='' />
                            <span className='text'>
                                {/* {t('home.call_us')} */}
                                <a
                                    className='phone__number'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={`tel:${props.phone}`}
                                >
                                    {props.phone}
                                </a>
                            </span>
                        </div>

                        <div className='phone email__address'>
                            <img height='20' src='/images/envelope.png' alt='' />
                            <span className='text'>
                                {/* {t('home.email_us')} */}
                                <a
                                    className='phone__number'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={`mailto:${props.email}`}
                                >
                                    {props.email}
                                </a>
                            </span>
                        </div>
                    </div>
                    {isAuth['isLogin'] && isAuth['isVendor'] && userData['isadmin'] !== '' ? (
                        <div className='companystatus'>
                            <span className='mr-2'>Company Status</span>
                            <label
                                className='switch'
                                data-toggle='tooltip'
                                data-placement='left'
                                title='Company Status'
                            >
                                <input
                                    type='checkbox'
                                    onChange={handleChange}
                                    name='compnaystatus'
                                    checked={companyStatus ? true : false}
                                />
                                <span className='slider round'></span>
                            </label>
                        </div>
                    ) : null}
                    <div className='language__currency'>
                        <div className='currency__symbol'>
                            <span className=' title'>{t('home.currency')} &nbsp;</span>
                            <span>AED</span>
                        </div>

                        <div ref={myRef} className='custom__dropdown open'>
                            <button
                                className='dropdown__tigger'
                                type='button'
                                data-toggle='dropdown'
                                tabIndex='0'
                                onClick={() => setShowFlag(!showflag)}
                            >
                                <span className='language__item'>
                                    <img
                                        src={
                                            language === 'english'
                                                ? '/images/usa_flag.jpg'
                                                : '/images/uae_flag.jpg'
                                        }
                                        alt=''
                                    />
                                    {language === 'english' ? 'EN' : 'عربى'}
                                </span>
                                <span className='caret'></span>
                            </button>
                            <ul
                                className={`dropdown-menu language__dropdown ${
                                    showflag ? 'd-block' : ' d-none'
                                    }`}
                            >
                                <li
                                    className='language__item'
                                    onClick={() => changeLanguage('english')}
                                >
                                    <img src='/images/usa_flag.jpg' alt='' />
                                    EN
                                </li>
                                <li
                                    className='language__item'
                                    onClick={() => changeLanguage('arabic')}
                                >
                                    <img src='/images/uae_flag.jpg' alt='' />
                                    عربى
                                </li>
                            </ul>
                        </div>

                        <ShareWebsite email={props.email} longtext={props.longtext} sharepagelink={props.sharepagelink} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopNavbar;
