import React, { useContext, useEffect } from 'react';
import HomeSlider from './../component/home/homeSlider';
import ProductCatagory from '../component/home/productCatagory';
import ProductSlider from '../component/home/productSlider';
import CustomerReview from '../component/home/customerReview';
import AppBanner from '../component/home/app-banner';
import Notification from './../component/template/notification';
import VendorLogin from '../component/home/vendorLogin'
import { LoginContext } from './../context/loginContext';
function Home(props) {
    const catagories = props.homeData.categories;
    const [isAuth] = useContext(LoginContext);
    const allCatagoris = catagories.map((c_info, index) => (
        <ProductSlider
            key={index}
            catagoryName={c_info['categoryname']}
            catagoryProduct={c_info['subcategory']}
        />
    ));
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            this.setState({
                locationError: 'Geolocation is not supported by this browser.',
            });
        }
    }, [])
    const showPosition = (position) => {
        const L = position.coords.latitude + ',' + position.coords.longitude;
    };
    return (
        <div>
            <div className='main-container'>
                <div className='container'>
                    {props.homeData.shownotify === '1' ? (
                        <Notification msg={props.homeData['notifymessage']}></Notification>
                    ) : null}
                    <HomeSlider sliderData={props.homeData.slidelist}></HomeSlider>
                    <ProductCatagory parentCategories={props.homeData.quicksubcategory} />
                    {allCatagoris}
                    <CustomerReview customerReviews={props.homeData.outletreview}></CustomerReview>
                    {isAuth['isLogin'] ? null :
                        <VendorLogin></VendorLogin>}
                    <AppBanner mobileprefix={props.homeData["mobileprefix"]} />
                </div>
            </div>
        </div>
    );
}
export default Home;
