import React, { useContext } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import { Link } from 'react-router-dom';
import DummyImage from './../shared/dummyImage';
import { LanguageContext } from './../../context/languageContext';

function ProductSlider(props) {
    const [language] = useContext(LanguageContext);
    const catagoryName = props.catagoryName;
    const sliderImages = props.catagoryProduct;

    const options = {
        pagination: false,
        nav: true,
        navText: ['', ''],
        items: 5,
        autoplay: true,
        rtl: language === 'arabic',
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };
    return (
        <div className='col-xl-12 content-box '>
            <div className='row row-featured'>
                <div className='col-xl-12  box-title '>
                    <div className='inner'>
                        <h2>
                            <span>{catagoryName} </span>
                            {/* <Link to="all-items" className="sell-your-item"> View more <i className="icon-th-list"></i> </Link> */}
                        </h2>
                    </div>
                </div>

                <div className='relative  content featured-list-row  w100 pr-15'>
                    {/* <nav className="slider-nav has-white-bg nav-narrow-svg">
                        <a className="prev" >
                            <span className="nav-icon-wrap"></span>

                        </a>
                        <a className="next">
                            <span className="nav-icon-wrap"></span>
                        </a>
                    </nav> */}
                    <OwlCarousel
                        refrence='car'
                        className='no-margin featured-list-slider owl-carousel owl-theme'
                        options={options}
                    >
                        {sliderImages.map((slideImage, index) => (
                            <div className='item' key={index} style={{ width: 200 }}>
                                <Link to={'item-services/' + slideImage['subcategorycode']}>
                                    <span className='item-carousel-thumb'>
                                        <DummyImage
                                            width='198'
                                            height='198'
                                            className='item-img'
                                            src={slideImage['imagelargeurl']}
                                        ></DummyImage>
                                        {/* <img className="item-img" src={slideImage["imagelargeurl"]} alt="img" /></span> */}
                                    </span>
                                    <span className='item-name'>
                                        {slideImage['subcategoryname']}
                                    </span>
                                </Link>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
}
export default ProductSlider;
