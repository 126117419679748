import React, { useState } from "react";
function Menu(props) {
  const [showMenu, SetNav] = useState(false);
  const handleOpen = () => {
    SetNav(!showMenu);
  };

  const handleClose = () => {
    SetNav(!showMenu);
  };
  return (
    <React.Fragment>
      <li
        className={props.authClass + " position-relative"}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <a href="#">
          <i className="icon-hourglass"></i>
          {props.menuname}
        </a>
        <ul
          className={`dropdown-menu user-menu dropdown-sub-menu  ${
            showMenu ? "show" : ""
            }`}
        >
          {props.children}
        </ul>
      </li>
    </React.Fragment>
  );
}
export default Menu;
