import axios from 'axios';
import { API_URL, MERCHANT_CODE } from './../config/constant';
let ipaddress = '127.0.0.1';
let latitude = '';
let longitude = '';
// const sessionid = Math.random().toString(36).substring(7);
axios.get('https://json.geoiplookup.io/').then(function (response) {
    // handle success
    // console.log('sss', response['data']['ip']);
    ipaddress = response['data']['ip'];
    latitude = response['data']['latitude'];
    longitude = response['data']['longitude'];
});
console.log('navigator', navigator);
let platform = navigator.platform;
let appversion = navigator.appVersion;
let width = window.innerWidth || document.body.clientWidth;
let height = window.innerHeight || document.body.clientHeight;
const sessionid = Math.random().toString(36).substring(7);
const loc =
    platform +
    '^' +
    'OS Version' +
    '^' +
    '64.0' +
    '^' +
    ipaddress +
    '^' +
    latitude +
    '^' +
    longitude +
    '^' +
    width +
    '^' +
    height +
    '^' +
    sessionid;
const api = axios.create({
    baseURL: API_URL,
    method: 'POST',
    responseType: 'json',
});
api.interceptors.request.use(function (config) {
    const json = {
        indata: {
            merchantcode: MERCHANT_CODE,
            mdevice: loc, //'PC^OS Version^64.0^127.0.0.1^25.109344,55.196632^1920^2550^ASWERFCDE1234321^',
        },
    };
    config.data = { indata: { ...json.indata, ...config.data } };
    // config.data = { ...config.data, ...json };
    return config;
});
api.interceptors.response.use(function (response) {
    let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'english';
    if (language === 'arabic') {
        let tempdata = JSON.stringify(response['data']);
        let res = tempdata.replace(/_a:/g, '');
        res = tempdata.replace(/_a"/g, '"');
        response['data'] = JSON.parse(res);
    }
    return response;
});
export default api;
