import { SIGN_UP } from "../actionConstant";
const signUp = (state = {}, action) => {
  switch (action.type) {
    case SIGN_UP:
      state = action.payload;
      break;
  }
  console.log("Sign Red", state);
  return state;
};
export default signUp;
