import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import generalApi from './../../services/generalApi';
import Loader from './../shared/loader';
let timeout = null;
const SearchBar = () => {
    const t = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showSuggestion, toggleSuggestions] = useState(false);
    const [searchtext, setText] = useState('');
    const [itemlist, setitemlist] = useState([]);
    const WAIT_INTERVAL = 300;

    const changetext = (e) => {
        setText(e.target.value);
    };
    useEffect(() => {
        if (searchtext !== '') {
            // getproductlist()
        }
    }, [searchtext]);
    const timeinterval = () => {
        setLoading(true);
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => getproductlist(), WAIT_INTERVAL);
    };
    const getproductlist = () => {
        let userdata = JSON.parse(localStorage.getItem('user_data'));
        let customerid = userdata !== null ? userdata['customerid'] : '';
        const json = {
            itemname: searchtext,
        };

        toggleSuggestions(true);

        generalApi.getData('SSMServiceItemList', json).then((response) => {
            if (response['data']['statuscode'] === '000') {
                console.log('search Text', response['data']);
                setLoading(false);
                setitemlist(response['data']['serviceitemlist']);
            } else {
            }
            // feautreList();
        });
    };

    const toggleSearchList = () => {
        toggleSuggestions(false);
    };

    return (
        <React.Fragment>
            <div className='form-inline search__box'>
                <div className='search_input w-sm-auto'>
                    <input
                        className='form-control mr-sm-2'
                        type='text'
                        onChange={changetext}
                        onKeyUp={timeinterval}
                        value={searchtext}
                        placeholder={t('header.search')}
                    />
                </div>
                <div className='search__suggestion'>
                    {showSuggestion && !loading && searchtext !== '' ? (
                        <div className='close__icon' onClick={toggleSearchList}></div>
                    ) : null}

                    {showSuggestion ? (
                        !loading && searchtext !== '' ? (
                            itemlist.length > 0 ? (
                                itemlist.map((info) => (
                                    <Link
                                        to={`/service-details/${info['itemcode']}`}
                                        className='suggestion__item d-flex'
                                        key={info['itemcode']}
                                        onClick={toggleSearchList}
                                    >
                                        <img
                                            src={info['itemimage']}
                                            alt='Example Search Item'
                                            className='suggestion__item-img'
                                        />
                                        <div className='suggestion__item-details'>
                                            <h5 className='item__title'>{info['itemname']}</h5>
                                            <p className='item__description'>{info['unitprice']}</p>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                    <div className='suggestion__item d-flex'>
                                        <div className='suggestion__item-details'>
                                            <h5 className='item__title'>{t('other.no_record')}</h5>
                                            <p className='item__description'>
                                                {t('other.search_sug')}
                                            </p>
                                        </div>
                                    </div>
                                )
                        ) : searchtext !== '' ? (
                            <div className='suggestion__item d-flex'>
                                <div className='suggestion__item-details text-center'>
                                    <img
                                        src='/images/loading.gif'
                                        alt='Loading Search Items'
                                        className='loading__image'
                                    />
                                </div>
                            </div>
                        ) : null
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
};

export default SearchBar;
