import React from 'react';
import useToggle from './../../hook/toggle';

const Accordion = ({ heading, children }) => {
    const { isOpen, handleClick } = useToggle();

    return (
        <div className='card faq__item'>
            <div className='card-header green_bc' id='headingOne'>
                <h5
                    className='mb-0 d-flex justify-content-between align-items-center faq__title ar__row-reverse'
                    onClick={handleClick}
                >
                    <button className='btn'>{heading}</button>

                    <i className='fa fa-angle-down text-white arrow__icon'></i>
                </h5>
            </div>

            <div className={`collapse ${isOpen ? 'show' : ''}`}>
                <div className='card-body'>{children}</div>
            </div>
        </div>
    );
};

export default Accordion;
