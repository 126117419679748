import React, { useState, createContext } from "react";
// Create Context of Booking
const OrderContext = createContext();
export default OrderContext
// Create Provider that hold the sharable information
export const OrderProvider = props => {
    const [orderStatus, setOrderStatus] = useState(0);
    return (
        <OrderContext.Provider value={[orderStatus, setOrderStatus]}>
            {props.children}
        </OrderContext.Provider>
    );
};
