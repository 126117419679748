import generalApi from "./../services/generalApi";
export const stripHtml = html => {
  var div = document.createElement("div");
  div.innerHTML = html;
  var text = div.textContent || div.innerText || "";
  return text;
};

export const to_float = number => {
  return parseFloat(number).toFixed(2);
};
export const vendorRating = (orderid, customerId) => {
  let user_data = JSON.parse(localStorage.getItem("user_data"));
  const json = {
    producttype: "2",
    comments: "Great",
    outlet: user_data["outletid"],
    starrating: "5",
    customer: customerId,
    employeeid: user_data["employeeid"],
    certificate: user_data["certificate"],
    orderid: orderid,
    reference: "Succedd",
    coupon: "",
    channelref: "Web"
  };
  generalApi.getData("SSMAddRatingVendor", json).then(response => {});
};
export const totalPrice = pricedetails => {
  let price =
    parseFloat(pricedetails["catalogprice"]) -
    parseFloat(pricedetails["catalogdiscount"]);
  price += parseFloat(pricedetails.sgst) + parseFloat(pricedetails.cgst);
  return price;
};

//Answer id Exist Only for Appointment
export const in_array = (array, id) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i].anserId === id) {
      return true;
    }
  }
  return false;
};
export const catalogCode = (question, bookingData) => {
  for (let index = 0; index < question.length; index++) {
    for (
      let index_1 = 0;
      index_1 < bookingData["questionAnswer"].length;
      index_1++
    ) {
      if (
        bookingData["questionAnswer"][index_1]["anserId"] ==
        question[index]["narrationcode"]
      ) {
        return bookingData["questionAnswer"][index_1]["anserId"];
      }
    }
    // console.log("Details", element["narrationcode"]);
  }
};
export const validCheckout = (validateComponent, pages) => {
  let is_validate = false;
  for (let index = 0; index < pages.length; index++) {
    if (index > 0 && validateComponent[pages[index]["pagecode"]] == true) {
      is_validate = true;
    }
  }
  if (validateComponent["finalpage"] == true) {
    is_validate = true;
  }
  console.log("validateComponentinner", is_validate);

  return is_validate;
};
