import React, { useState } from 'react';
function ProductImage(props) {
    const [loaded, setLoaded] = useState(false);
    return (
        <div>
            {!loaded ? (
                <svg width={props.width} height={props.height} className={props.classname}>
                    <rect width={props.width} height={props.height} className={props.classname} fill='#CCC' />
                </svg>
            ) : null}
            <img
                src={props.src}
                alt='Dummy'
                className={props.classname}
                style={!loaded ? { display: 'none' } : {}}
                onLoad={() => setLoaded(true)}
            />
        </div>
    );
}
export default ProductImage;
