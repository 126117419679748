import React, { useState, createContext } from "react";
// Create Context of Booking
export const CheckoutContext = createContext();

// Create Provider that hold the sharable information
export const CheckoutProvider = props => {
    const [checkoutData, setCheckoutData] = useState({
        data: {},
        catalog: ''
    });
    return (
        <CheckoutContext.Provider value={[checkoutData, setCheckoutData]}>
            {props.children}
        </CheckoutContext.Provider>
    );
};
