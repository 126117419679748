import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import generalApi from '../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { SignUpAction } from "../../store/action/generalAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang'
import { LoginContext } from "./../../context/loginContext";
import ResetCounter from './resetCounter';
class RegisterOtp extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        const { t } = this.props
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                min: t('validation.tokenmin'),
                max: t('validation.tokenmax'),
            }
        });
        this.state = { isactive: false, 'otp': '', signipData: JSON.parse(localStorage.getItem('signupData')) }
    }
    static contextType = LoginContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            this.setState({ otp: this.state.otp });
            const s = { ...this.state["signipData"] };
            let language = localStorage.getItem('language');
            language = language === "arabic" ? "A" : "E"
            const json = {
                ...s,
                otp: this.state.otp,
                gender: 'U',
                address: this.state["signipData"]["useraddress"],
                additionalinfo: localStorage.getItem('referralmobileno') === null ? "" : localStorage.getItem('referralmobileno'),//Refferal
                mobile: this.state["signipData"]['mobileprefix'] + this.state["signipData"]['mobile'], segment: '1000', language: language
            }
            generalApi.getData('SSMfirsttime', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    const Sjson = { customer: this.state["signipData"]['mobileprefix'] + this.state["signipData"]['mobile'], password: this.state.signipData.pin }
                    generalApi.getData('SSMvalidateUser', Sjson).then(response => {
                        if (response["data"]["statuscode"] == "000") {
                            localStorage.setItem('user_data', JSON.stringify(response["data"]));
                            localStorage.removeItem('signupData')
                            const [isAuth, setAuth] = this.context;
                            const loginInfo = isAuth;
                            loginInfo["isLogin"] = true;
                            loginInfo["isVendor"] = false;
                            setAuth({ ...loginInfo });
                            const { t } = this.props
                            let msg = {
                                heading: t('message.success'),
                                des: t('message.signupmessage'),
                                link: '/',
                                linkname: t('message.home'),
                            };
                            localStorage.setItem('message', JSON.stringify(msg));

                            if (!this.props.isModel) {
                                this.props.history.push('/message')
                            } else {
                                this.props.changeStatus();
                            }
                        } else {
                            this.setState({ isactive: false });
                            toast.error(response["data"]["statusdesc"]);
                        }
                    });
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }

            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (!this.state.signipData) {
            return <Redirect to="/signup"></Redirect>
        }
        const { t } = this.props
        return (
            <div className="main-container" >
                <div className="container">
                    <div className="row">
                        <div className={`
									${this.props.isModel ? 'col-12 login-box' : 'col-sm-5 login-box signup-box'}
								`}>
                            <div className="card card-default">
                                <div className="panel-intro text-center">
                                    <img src="/images/logo.png" className="page-logo-width" alt="" />
                                </div>
                                <div className="card-body">
                                    <form role="form" onSubmit={this.submitForm} >
                                        <div className="form-group">
                                            <div className="col-md-12">
                                                <label className="control-label">{t('register_otp.otp')}</label>
                                                <div className="input-icon">
                                                    <i className='icon-lock fa'></i>
                                                    <input placeholder={t('register_otp.6_digit_sms')} name="otp" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('otp')} value={this.state.otp} className="form-control englisg-text" type="text" />
                                                </div>
                                                <span className="text-danger">{this.validator.message('otp', this.state.otp, 'required|min:6|max:6')}</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" disabled={this.state.isactive ? true : false} className="btn btn-primary btn-lg btn-block">
                                                {this.state.isactive ? t('other.processing') : t('register_otp.validate')}
                                            </button>
                                            <ResetCounter time={30} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default withRouter(withTranslation(RegisterOtp));