import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import generalApi from '../../services/generalApi';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { LoginContext } from './../../context/loginContext';
import 'react-toastify/dist/ReactToastify.css';
// import { getToken } from './../../fcm/generateToken';
import { T, withTranslation } from 'react-multi-lang';
class Login extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T,
        };
        localStorage.removeItem('reset_pin');
        this.mobileprefix = '+971';
        const { t } = this.props;
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                phone: t('validation.phone'),
                min: t('validation.pinmin'),
                max: t('validation.pinmax'),
                email: t('validation.email'),
            },
        });
        this.state = { pin: '', mobile: '', isactive: false, isactiveforget: false };
        // getToken();
    }
    static contextType = LoginContext;
    handleChange = (e) => {
        if (e.target.name === 'mobile') {
            let mobileprefix = this.mobileprefix;
            let mobile = e.target.value.slice(mobileprefix.length);
            if (mobile.length > 9) {
                mobile = mobile.substring(0, 9);
            }
            this.setState({ ...this.state, [e.target.name]: mobile });
        } else {
            this.setState({ ...this.state, [e.target.name]: e.target.value });
        }
    };
    registerfcm = (response) => {
        const json = {
            customer: response['customerid'],
            certificate: response['certificate'],
            reference: localStorage.getItem('fcm-token'),
        };
        generalApi.getData('SSMUpdateFCMKey', json).then((response) => { });
    };
    submitForm = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            const json = {
                customer: this.mobileprefix + this.state.mobile,
                password: this.state.pin,
            };
            generalApi.getData('SSMvalidateUser', json).then((response) => {
                if (response['data']['statuscode'] == '000') {
                    localStorage.setItem('user_data', JSON.stringify(response['data']));
                    const [isAuth, setAuth] = this.context;
                    const loginInfo = isAuth;
                    loginInfo['isLogin'] = true;
                    loginInfo['isVendor'] = false;
                    setAuth({ ...loginInfo });
                    // this.registerfcm(response['data']);
                    if (!this.props.isModel) {
                        this.props.history.push('/');
                    } else {
                        this.props.changeStatus();
                    }
                } else {
                    this.setState({ isactive: false });
                    toast.error(response['data']['statusdesc']);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    forgetPassword = () => {
        if (this.validator.fieldValid('mobile')) {
            this.setState({ isactiveforget: true });
            const json = {
                mobile: this.mobileprefix + this.state.mobile

            };
            generalApi.getData('SSMSendEnrolmentToken', json).then((response) => {
                if (response['data']['statuscode'] == '000') {
                    // toast.success(response['data']['statusdesc']);
                    const json = {
                        customer: this.mobileprefix + this.state.mobile,
                        reference: response['data']['referencenumber'],
                    };
                    localStorage.setItem('reset_pin', JSON.stringify(json));
                    if (!this.props.isModel) {
                        this.props.history.push('/forgot-otp');
                    } else {
                        this.props.forgetStatus();
                    }
                } else {
                    this.setState({ isactiveforget: false });
                    toast.error(response['data']['statusdesc']);
                }
            });
        } else {
            this.validator.showMessageFor('mobile');
            this.forceUpdate();
        }
    };
    signupmodel = () => {
        this.props.signupStatus();
    }
    render() {
        const { t } = this.props;
        return (
            <div className='main-container'>
                <div className='container'>
                    <div className='row'>
                        <div
                            className={`
									${this.props.isModel ? 'col-12 login-box' : 'col-sm-5 login-box signup-box'}
								`}
                        >
                            <div className='bg-white p-3 br-5'>
                                <div
                                    className={
                                        this.props.isModel
                                            ? 'card card-default border-0'
                                            : 'card card-default'
                                    }
                                >
                                    <div className='panel-intro text-center'>
                                        <img src='/images/logo.png' className='page-logo-width' />
                                    </div>
                                    <div className='card-body'>
                                        <form onSubmit={this.submitForm}>
                                            <div className=''>
                                                <label className='control-label'>
                                                    {t('login.mobile')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='icon-mobile fa'></i>
                                                    <input
                                                        id='sender-email'
                                                        type='text'
                                                        name='mobile'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor('mobile')
                                                        }
                                                        value={
                                                            this.mobileprefix + this.state.mobile
                                                        }
                                                        placeholder={t(
                                                            'login.login_primary_mobile'
                                                        )}
                                                        className='form-control email englisg-text'
                                                    />
                                                    <span className='text-danger'>
                                                        {this.validator.message(
                                                            'mobile',
                                                            this.state.mobile,
                                                            'required'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label className='control-label'>
                                                    {t('login.password')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='icon-lock fa'></i>
                                                    <input
                                                        type='password'
                                                        name='pin'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor('pin')
                                                        }
                                                        value={this.state.pin}
                                                        className='form-control englisg-text'
                                                        placeholder={t('login.password')}
                                                        id='user-pass'
                                                    />
                                                    <span className='text-danger'>
                                                        {this.validator.message(
                                                            'pin',
                                                            this.state.pin,
                                                            'required|min:4|max:4'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <button
                                                    type='submit'
                                                    disabled={this.state.isactive ? true : false}
                                                    className='btn btn-primary  btn-block'
                                                >
                                                    {this.state.isactive
                                                        ? 'Processing...'
                                                        : t('login.login')}
                                                </button>

                                                {/* {!this.props.isModel ? ( */}
                                                <button
                                                    onClick={this.forgetPassword}
                                                    disabled={
                                                        this.state.isactiveforget ? true : false
                                                    }
                                                    className='btn  btn-link btn-block'
                                                    type='button'
                                                >
                                                    {this.state.isactiveforget
                                                        ? 'Processing...'
                                                        : t('login.lost_password')}
                                                </button>
                                                {/* ) : null} */}

                                                {!this.props.isModel ? (
                                                    <div className='login-box-btm text-center'>
                                                        <Link
                                                            to='/signup'
                                                            className='btn btn-block btn-outline-primary'
                                                        >
                                                            <strong>{t('login.signup')} </strong>{' '}
                                                        </Link>
                                                    </div>
                                                ) : <div className='login-box-btm text-center'>
                                                        <button
                                                            onClick={this.signupmodel}
                                                            className='btn btn-block btn-outline-primary'
                                                        >
                                                            <strong>{t('login.signup')} </strong>{' '}
                                                        </button>
                                                    </div>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(withTranslation(Login));
