import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { HomeAction } from './store/action/generalAction';
import HomeData from './services/home';
import Routes from './config/route';
import Loader from './component/shared/loader';
// import FirebaseNotification from './component/shared/firebaseNotification';
import 'react-owl-carousel2/src/owl.carousel.css';
import { LoginProvider } from './context/loginContext';
import { LanguageContext } from './context/languageContext';
function App(props) {
    const [loading, setLoading] = useState(true);
    const [language] = useContext(LanguageContext);

    useEffect(() => {
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (iOS) {
            document
                .querySelector('#zoom__metadata')
                .setAttribute(
                    'content',
                    'width=device-width, initial-scale=1, user-scalable=no,maximum-scale=1.0'
                );
        }
    }, []);

    useEffect(() => {
        HomeData.slider().then((response) => {
            props.setHomeData(response['data']);

            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (language === 'english') {
            document.querySelector('html').setAttribute('lang', 'en');
        } else {
            document.querySelector('html').setAttribute('lang', 'ar');
        }
    }, [language]);

    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div id='wrapper'>
            <LoginProvider>
                {/* <FirebaseNotification show={false}></FirebaseNotification> */}
                <Routes homeData={props.homeData}></Routes>
            </LoginProvider>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        homeData: state.generalData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHomeData: (data) => {
            dispatch(HomeAction(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
