import React, { useState, useContext } from "react";
import { LoginContext } from "./../context/loginContext";
export function IsLogin() {
  // let contextType = LoginContext;
  // const [VendorData, setVendorData] = this.context;
  // const [isAuth, setAuth] = useContext(LoginContext);
  // console.log("isloginCon", isAuth);

  const user_data = localStorage.getItem("user_data");
  return user_data ? true : false;
}
export function IsVendor() {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  return user_data && user_data["is_vendor"] ? true : false;
}
