import React, { useEffect } from "react";
import generalApi from '../../services/generalApi'
import { toast } from 'react-toastify';
import { useParams, withRouter } from "react-router-dom";
import { useTranslation } from 'react-multi-lang';
function Paytabs(props) {
    let { status, bankcode } = useParams();
    const t = useTranslation();
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        let orderinfo = JSON.parse(localStorage.getItem('orderinfo'))
        if (orderinfo && bankcode !== undefined && status !== undefined) {
            const json = {
                customer: userData['customerid'],
                certificate: userData['certificate'],
                orderid: orderinfo["orderid"],
                netamount: orderinfo["netamount"],
                outlet: orderinfo["vendorid"],
                paymentref: bankcode,
                reference: 'Transaction Successful',
                fbtoken: localStorage.getItem('fcm-token'),
                status: orderinfo["status"],
                channelref: "WEB",
                fbtoken: localStorage.getItem('fcm-token')
            };
            localStorage.removeItem('orderinfo')
            generalApi.getData('SSMpayByPayTM', json).then((response) => {
                if (response['data']['statuscode'] == '000') {
                    let msg = {
                        heading: t('message.success'),
                        des: t('message.cardpayment', { orderid: orderinfo["orderid"] }),
                        link: "/current-order",
                        linkname: t('message.myorder')
                    };
                    localStorage.setItem('message', JSON.stringify(msg));
                    props.history.push('/message');
                } else {
                    toast.error(response['data']['statusdesc']);
                    props.history.push('/current-order');
                }
            });
        }
    }, [])
    return (
        <React.Fragment>

        </React.Fragment>
    )
}
export default withRouter(Paytabs)