import React, { useEffect, useState } from 'react';
import generalApi from '../../services/generalApi'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-multi-lang'
const ResetCounter = ({ time }) => {
    const [duration, setDuration] = useState(time);
    const t = useTranslation()
    useEffect(() => {
        if (duration > 0) {
            setTimeout(() => {
                setDuration(duration - 1);
            }, 1000);
        }
    }, [duration]);

    const handleResendOtp = () => {
        if (duration === 0) {
            let userData = JSON.parse(localStorage.getItem("reset_pin"))

            const json = { mobile: userData["customer"] };
            generalApi.getData('SSMSendEnrolmentToken', json).then((response) => {
                if (response['data']['statuscode'] == '000') {
                    // toast.success(response['data']['statusdesc']);
                    const json = {
                        customer: userData["customer"],
                        reference: response['data']['referencenumber'],
                    };
                    setDuration(time);
                    localStorage.setItem('reset_pin', JSON.stringify(json));
                    // this.props.history.push('forgot-otp');
                } else {
                    // this.setState({ isactiveforget: false });
                    toast.error(response['data']['statusdesc']);
                }
            });
        }
    };

    return (
        <button
            className='btn btn-link btn-block'
            disabled={duration > 0}
            onClick={handleResendOtp}
        >
            {duration > 0 ?
                t('message.resend_otp_timer', { timer: duration })
                : t('message.resend_otp')
            }
            {/* {t('message.resend_otp', { timer: duration > 0 ? `in ${duration} seconds` : null })} */}
            {/* Resend OTP {duration > 0 ? `in ${duration} seconds` : null} */}
        </button>
    );
};

export default ResetCounter;
