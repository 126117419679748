import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-multi-lang'

function ProductCatagory(props) {
    const t = useTranslation()
    const categories = props.parentCategories;
    const Categories = categories.map((category, index) =>
        <div className="col-lg-3 col-xs-12  col-sm-6 f-category" key={index}>
            <div className="inner">
                <Link to={`/item-services/${category["subcategorycode"]}`}>
                    <span className="imgBox">
                        <img src={category["imageiconurl"]} className="img-responsive" alt="img" />
                    </span>
                    <h6> {category["subcategoryname"]} </h6>
                </Link>
            </div>
        </div>
    );
    return (
        <div className="col-xl-12 content-box ">
            <div className="row row-featured row-featured-category">
                <div className="col-xl-12  box-title no-border">
                    <div className="inner">
                        <h2><span>{t('home.catagory_heading')} </span>
                            {/* <Link to="/all-items" className="sell-your-item"> View more <i className="  icon-th-list"></i> </Link> */}
                        </h2>
                    </div>
                </div>
                {Categories}
            </div >
        </div>
    );
}
export default ProductCatagory;