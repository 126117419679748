import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import generalApi from '../../services/generalApi';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang';
import ResetCounter from './resetCounter';
class ForgetOtp extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T,
        };
        const { t } = this.props;
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                min: t('validation.tokenmin'),
                max: t('validation.tokenmax'),
            },
        });
        this.state = {
            isactive: false,
            idnumber: '',
            reset_pin: JSON.parse(localStorage.getItem('reset_pin')),
        };
    }

    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    };
    submitForm = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            this.setState({ otp: this.state.otp });
            const s = { ...JSON.parse(localStorage.getItem('reset_pin')) };
            const json = {
                ...s,
                idnumber: this.state.idnumber
            };
            console.log(json);

            generalApi.getData('SSMValidateToken', json).then((response) => {
                if (response['data']['statuscode'] == '000') {
                    if (!this.props.isModel) {
                        this.props.history.push('/reset-password');
                    } else {
                        this.props.resetStatus();
                    }
                } else {
                    this.setState({ isactive: false });
                    toast.error(response['data']['statusdesc']);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    render() {
        if (!this.state.reset_pin) {
            return <Redirect to='/login'></Redirect>;
        }
        const { t } = this.props;
        return (
            <div className='main-container'>
                <div className='container'>
                    <div className='row'>
                        <div className={`
									${this.props.isModel ? 'col-12 login-box' : 'col-sm-5 login-box signup-box'}
								`}>
                            <div className='card card-default'>
                                <div className='panel-intro text-center'>
                                    <img
                                        src='/images/logo.png'
                                        className='page-logo-width'
                                        alt=''
                                    />
                                </div>
                                <div className='card-body'>
                                    <form role='form' onSubmit={this.submitForm}>
                                        <div className='form-group'>
                                            <div className='col-md-12'>
                                                <label className='font-weight-bold control-label'>
                                                    {t('register_otp.otp')}
                                                </label>
                                                <div className='input-icon'>
                                                    <i className='icon-lock fa'></i>
                                                    <input
                                                        placeholder={t('register_otp.6_digit_sms')}
                                                        name='idnumber'
                                                        onChange={this.handleChange}
                                                        onBlur={() =>
                                                            this.validator.showMessageFor(
                                                                'idnumber'
                                                            )
                                                        }
                                                        value={this.state.idnumber}
                                                        className='form-control englisg-text'
                                                        type='text'
                                                    />
                                                </div>
                                                <span className='text-danger'>
                                                    {this.validator.message(
                                                        'idnumber',
                                                        this.state.idnumber,
                                                        'required|min:6|max:6'
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <button
                                                type='submit'
                                                disabled={this.state.isactive ? true : false}
                                                className='btn btn-primary btn-lg btn-block'
                                            >
                                                {this.state.isactive
                                                    ? t('other.processing')
                                                    : t('register_otp.validate')}
                                            </button>

                                            <ResetCounter time={30} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(withTranslation(ForgetOtp));
